.theme-dark{
    .h1, .h2, .h3, .h4, .h5, .h6, h1, h2, h3, h4, h5, h6, .mat-headline, small, span, p, .form-label, label {
        color: var(--gray-400);
    }
}

.h1, .h2, .h3, .h4, .h5, .h6, h1, h2, h3, h4, h5, h6 {
    color: #343a40 ;
    font-family: Inter,sans-serif;
}

h3, .h3 {
    font-size: 1.575rem;
}
h1, h2, h3, h4, h5, h6, .h1, .h2, .h3, .h4, .h5, .h6 {
    margin-bottom: 0.5rem;
    font-weight: 500;
    line-height: 1.2;
}
h1, h2, h3, h4, h5, h6 {
    margin-top: 0;
    margin-bottom: 0.5rem;
}
