.mat-mdc-radio-button{
    --mdc-radio-selected-icon-color: var(--primary);
    --mdc-radio-selected-hover-icon-color: var(--primary);
}
.mat-mdc-radio-button label{
    margin: 0;
}
.form-row {
    display: flex;
    flex-wrap: wrap;
    margin-right: -5px;
    margin-left: -5px;
}

.input-group {
    position: relative;
    display: flex;
    flex-wrap: wrap;
    align-items: stretch;
    width: 100%;

    &>.form-control, &>.form-control-plaintext, &>.custom-select, &>.custom-file {
        position: relative;
        flex: 1 1 auto;
        width: 1%;
        min-width: 0;
        margin-bottom: 0;
    }
}

.form-control {
    display: block;
    width: 100%;
    height: calc(1.5em + 0.94rem + 2px);
    padding: 0.47rem 0.75rem;
    font-family: inherit;
    font-size: .9rem;
    font-weight: 400;
    line-height: 1.5;
    color: #505d69;
    background-color: #fff;
    background-clip: padding-box;
    border: 1px solid #ced4da;
    border-radius: 0.25rem;
    transition: border-color .15s ease-in-out,box-shadow .15s ease-in-out;

    &:focus {
        color: #505d69;
        background-color: #fff;
        border-color: #b1bbc4;
        outline: 0;
        box-shadow: none;
    }
}

select.form-control[size], select.form-control[multiple], textarea.form-control{
    height: auto;
}


input, button, select, optgroup, textarea {
    margin: 0;
    font-family: inherit;
    font-size: inherit;
    line-height: inherit;
}