.float-left{ float: left; }
.float-right{ float: right; }

.d-flex{ display: flex !important; }
.align-items-center{ align-items: center !important; }
.align-items-end{ align-items: end !important; }
.justify-content-center { justify-content: center !important; }
.justify-content-between { justify-content: space-between!important; }
.justify-content-end{ justify-content: flex-end !important; }

.flex-grow-1 { flex-grow: 1!important; }
.flex-column { flex-direction: column!important; }

.flex-wrap{ flex-wrap: wrap !important; }

$breakpoints: 576px, 768px, 992px, 1200px, 1400px;
$screens: 'sm', 'md', 'lg', 'xl', 'xxl';

@each $breakpoint in $breakpoints {
    @media (min-width: $breakpoint){
        @each $screen in $screens {
            .d-#{$screen}-flex{ display: flex !important; }
            .align-items-#{$screen}-center{ align-items: center !important; }
            .justify-content-#{$screen}-center { justify-content: center !important; }
            .justify-content-#{$screen}-between { justify-content: space-between!important; }
            .justify-content-#{$screen}-end{ justify-content: flex-end !important; }

            .flex-#{$screen}-grow-1 { flex-grow: 1!important; }

            .flex-#{$screen}-column { flex-direction: column!important; }
            .flex-#{$screen}-row { flex-direction: row!important; }
        }
    }
}
