$border-color: var(--gray-200);
$border-color-light: var(--gray-700);

$borders: (
    t: border-top,
    r: border-right,
    b: border-bottom,
    l: border-left,
);

$borderColors: (
    gray: gray-400,
    dark: dark,
    white: white,
    danger: danger,
);

@each $key, $value in $borders{
    .border-#{$key}{ #{$value}: 1px solid; }
}

@each $key, $value in $borderColors{
    .border-#{$key}{ border-color: var(--#{$value}); }
}

.border-0{ border: 0 !important; }
.border-left{
    border-left: 1px solid $border-color;
}

.theme-dark .border-left{
    border-left: 1px solid $border-color-light;
}

.rounded {
    border-radius: 0.25rem!important;
}