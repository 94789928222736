.d-none { display: none!important;}
.d-block {display: block !important;}
.d-inline-block {display: inline-block !important;}
.d-flex {display: flex !important;}
.d-inline-flex {display: inline-flex !important;}
.d-grid {display: grid !important;}
.d-inline-grid {display: inline-grid !important;}
.d-table-cell {display: table-cell !important;}

$breakpoints: 576px, 768px, 992px, 1200px, 1400px;
$screens: 'sm', 'md', 'lg', 'xl', 'xxl';

@each $breakpoint in $breakpoints {
  @media (min-width: $breakpoint){
    @each $screen in $screens {
      .d-#{$screen}-none { display: none!important;}
      .d-#{$screen}-block {display: block !important;}
      .d-#{$screen}-inline-block {display: inline-block !important;}
      .d-#{$screen}-flex {display: flex !important;}
      .d-#{$screen}-inline-flex {display: inline-flex !important;}
      .d-#{$screen}-grid {display: grid !important;}
      .d-#{$screen}-inline-grid {display: inline-grid !important;}
      .d-#{$screen}-flex {display: flex!important;}
      .d-#{$screen}-table-cell {display: table-cell!important;}
    }
  }
}


.visible{ visibility: visible !important; }
.invisible{ visibility: hidden !important; }
