
$bgColors: (
    white: white,
    dark: dark,
    primary: primary,
    success: success,
    warning: warning,
    danger: danger,
    info: info,
    light: gray-100
) !default;


@each $key, $value in $bgColors{
    .bg-#{$key}{ background-color: var(--#{$value}) !important; }
}

.bg-light{ background-color: var(--gray-100) !important;}