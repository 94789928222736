.no-gutters>.col, .no-gutters>[class*=col-]{
    padding-left: 0 !important;
    padding-right: 0 !important;
}

// ------------padding-----------------
$margin-padding-values: (
  0: 0,
  1: 0.25rem,
  2: 0.5rem,
  3: 1rem,
  4: 1.5rem,
  5: 3rem
);

@each $key, $value in $margin-padding-values {
  $multiplier: $key;
  
  .p-#{$multiplier} {
    padding: $value !important;
  }
  
  .pl-#{$multiplier}, .px-#{$multiplier} {
    padding-left: $value !important;
  }
  
  .pr-#{$multiplier}, .px-#{$multiplier} {
    padding-right: $value !important;
  }
  
  .pt-#{$multiplier}, .py-#{$multiplier} {
    padding-top: $value !important;
  }
  
  .pb-#{$multiplier}, .py-#{$multiplier} {
    padding-bottom: $value !important;
  }

  // padding
  @media (min-width: 768px){
    .p-md-2 {
      padding: 0.5rem!important;
    }
    .p-md-3 {
      padding: 1rem!important;
    }
    .pb-md-0, .py-md-0 {
      padding-bottom: 0!important;
    }

    .pt-md-0, .py-md-0 {
      padding-top: 0!important;
    }

    .pr-md-5, .px-md-5 {
      padding-right: 3rem!important;
    }
    .pl-md-5, .px-md-5 {
      padding-left: 3rem!important;
    }
    .ml-md-4{
      margin-left: 1.5rem !important
    }
  }
  
  @media (min-width: 576px){
    .p-sm-3 {
      padding: 1rem!important;
    }
  }

//   margin
  .m-#{$multiplier} {
    margin: $value !important;
  }
  
  .ml-#{$multiplier}, .mx-#{$multiplier} {
    margin-left: $value !important;
  }
  
  .mr-#{$multiplier}, .mx-#{$multiplier} {
    margin-right: $value !important;
  }
  
  .mt-#{$multiplier}, .my-#{$multiplier} {
    margin-top: $value !important;
  }
  
  .mb-#{$multiplier}, .my-#{$multiplier} {
    margin-bottom: $value !important;
  }
}

// ------------margin-----------------
.no-gutters {
    margin-right: 0 !important;
    margin-left: 0 !important;
}

.ml-auto, .mx-auto {
    margin-left: auto!important;
}
.mr-auto, .mx-auto {
    margin-right: auto!important;
}

@media (min-width: 1200px){
    .pl-xl-4, .px-xl-4 {
        padding-left: 1.5rem!important;
    }
    .p-xl-5 {
        padding: 3rem!important;
    }
    .pl-xl-5, .px-xl-5 {
        padding-left: 3rem!important;
    }
    .pr-xl-4, .px-xl-4 {
        padding-right: 1.5rem!important;
    }

    .ml-xl-5, .mx-xl-5 {
        margin-left: 3rem!important;
    }
    .mr-xl-5, .mx-xl-5 {
        margin-right: 3rem!important;
    }
}
