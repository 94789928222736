.table {
    width: 100%;
      tr.mat-mdc-row, tr.mat-mdc-footer-row {
        height: 64px;
      }
  }
  
  //Table centered
  .table-centered {
    td,th {
        vertical-align: middle !important;
    }
  }
  
  .table-nowrap {
    th, td {
      white-space: nowrap;
    }
  }

  .mdc-data-table__cell{
    overflow: visible !important;
  }

  td.mat-mdc-cell{
    padding: 0;
  }
  
  .mdc-data-table__header-cell{
    padding-left: 0 !important;
  }

  th.mat-mdc-header-cell:first-of-type, td.mat-mdc-cell:first-of-type, td.mat-mdc-footer-cell:first-of-type{
    padding-left: 24px !important;
  }
  