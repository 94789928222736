button, .btn{
    display: inline-block;
    font-family: inherit;
    font-weight: 400;
    color: #505d69;
    text-align: center;
    vertical-align: middle;
    -webkit-user-select: none;
    user-select: none;
    cursor: pointer;
    background-color: transparent;
    border: 1px solid transparent;
    padding: 0.47rem 0.75rem;
    font-size: .9rem;
    line-height: 1.5;
    border-radius: 0.25rem;
    white-space: nowrap;
    transition: color .15s ease-in-out,background-color .15s ease-in-out,border-color .15s ease-in-out,box-shadow .15s ease-in-out;

    &:disabled{
        opacity: 0.65;
        cursor: default;
    }
}

.btn-group{
    &>.btn{
        position: relative;
        flex: 1 1 auto;
        border-radius: 0;
    }
}

.btn-sm, .btn-group-sm>.btn{
    padding: 0.25rem 0.5rem;
    font-size: .7875rem;
    line-height: 1.5;
    border-radius: 0.2rem;
}


$colors: (primary, danger, warning, info);

@each $color in $colors {
    $primary: var(--#{$color});

    .btn-#{$color}{
        color: var(--white);
        background-color: $primary;
        border-color: $primary;
    }

  .btn-outline-#{$color} {
        color: $primary;
        border-color: $primary;

    &:hover{
        color: var(--white);
        background-color: $primary;
    }
  }
}
