*{
    letter-spacing: 0;
}

.theme-dark{
    --dark: var(--gray-500) !important;
}

.theme-dark .text-muted{
    color: #919bae !important;
}
.text-muted{
    color: #74788d !important;
}

$colors: white, dark, primary, success, warning, danger, info;

@each $color in $colors{
    .text-#{$color}{ color: var(--#{$color}) !important; }
}

.text-right{ text-align: right !important; }
.text-left{ text-align: left !important; }
.text-center{ text-align: center !important; }
.text-justify{ text-align: justify !important; }

@media (min-width: 768px){
    .text-md-left {
        text-align: left!important;
    }
}
a {
    color: var(--primary);
    text-decoration: none;
    background-color: transparent;
}

h1,.h1 {font-size: 2.25rem}
h2,.h2 {font-size: 1.8rem}
h3,.h3 {font-size: 1.575rem}
h4,.h4 {font-size: 1.35rem}
h5,.h5 {font-size: 1.125rem}
h6,.h6 {font-size: .9rem}

label{ 
    display: inline-block;
    margin-bottom: 0.5rem;
    font-weight: 600;
}

.font-italic {
    font-style: italic!important;
}

small, .small {
    font-size: 80%;
    font-weight: 400;
}


.lead {
    font-size: 1.125rem;
    font-weight: 300
}

.display-1 {
    font-size: 6rem;
    font-weight: 300;
    line-height: 1.2
}

.display-2 {
    font-size: 5.5rem;
    font-weight: 300;
    line-height: 1.2
}

.display-3 {
    font-size: 4.5rem;
    font-weight: 300;
    line-height: 1.2
}

.display-4 {
    font-size: 3.5rem;
    font-weight: 300;
    line-height: 1.2
}

hr {
    margin-top: 1rem;
    margin-bottom: 1rem;
    border: 0;
    border-top: 1px solid rgba(0,0,0,.1)
}

.text-truncate {
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    margin: 0 !important;
}

.text-uppercase{ text-transform: uppercase; }
.text-nowrap {
    white-space: nowrap !important;
}

// -------------font weight----------------
$font-weight: light, lighter, bold, bolder;

@each $weight in $font-weight {
    .font-weight-#{$weight}{
        font-weight: $weight;
    }
}


// ------------mat-headings---------------- //
.mat-h1, .mat-headline, .mat-typography .mat-h1, .mat-typography .mat-headline, .mat-typography h1 {
    font-size: 24px;
    font-weight: 400;
    line-height: 32px;
    font-family: Inter,sans-serif;
    letter-spacing: normal;
    margin: 0 0 16px;
}


$fontSizes: 13, 14;

@each $fontSize in $fontSizes{
    .fs-#{$fontSize}{ font-size: #{$fontSize}px !important; }
}
