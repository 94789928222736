$sizes: (
    unset: unset,
    auto: auto,
    25: 25%,
    50: 50%,
    75: 75%,
    100: 100%
);

@each $key, $value in $sizes{
    .h-#{$key}{ height: $value !important}
    .w-#{$key}{ width: $value !important}
}