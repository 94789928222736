.card {
    margin-bottom: 24px;
    box-shadow: 0 2px 4px #00000014;
    overflow: hidden;
}

.card {
    position: relative;
    display: flex;
    flex-direction: column;
    min-width: 0;
    word-wrap: break-word;
    background-color: #fff;
    background-clip: border-box;
    border: 0 solid #f1f5f7;
    border-radius: .25rem
}

.card-body {
    flex: 1 1 auto;
    min-height: 1px;
    padding: 1.25rem;
}