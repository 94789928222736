.toast-container {
    pointer-events: none;
    position: fixed;
    z-index: 9999999;
}

.toast-container .ngx-toastr {
    border-radius: 0.25rem;
    position: relative;
    overflow: hidden;
    margin: 0 0 6px;
    padding: 0.75rem 1.25rem 0.75rem 50px;
    width: 30rem;
    background-position: 15px center;
    background-repeat: no-repeat;
    background-size: 24px;
    box-shadow: 0 0 10px #00000008;
    pointer-events: auto;
}

.toast-top-right {
    top: 12px;
    right: 12px;
}