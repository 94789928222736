@use '@angular/material' as mat;
@include mat.all-component-typographies();
@include mat.core();
$custom-typography: mat.define-typography-config(
  $font-family: "Inter"
);
@include mat.all-component-typographies($custom-typography);

$mobile-width: 576px;
$tablet-width: 768px;

// $button-main-color: lightblue;

// Primary colour
$my-app-primary: mat.define-palette(mat.$indigo-palette);
// Accent colour
$my-app-accent: mat.define-palette(mat.$pink-palette, A200, A100, A400);

//default theme
// @import "assets/scss/bootstrap";
@import "assets/scss/app";
//Fonts
 @import "assets/scss/custom/fonts/fonts";
 @import "assets/scss/custom/fonts/icon";
/* Calander default theme */
@import "angular-calendar/scss/angular-calendar.scss";

a{ text-decoration: none; }
.bg-transparent{ background-color: transparent !important; }
svg{ vertical-align: middle; }

body {
	font-family: "Inter", sans-serif !important;
}
.mat-calendar-table-header span {
    font-weight: 400;
}
.mat-calendar-table-header-divider:after {
    content: "";
    position: absolute;
    top: 0;
    left: -8px;
    right: -8px;
    height: 1px;
    background: rgba(0, 0, 0, .12);
}
thead.mat-calendar-table-header {
    color: rgba(0, 0, 0, .38);
}
.playlist_input .mdc-text-field:focus-within{
    outline: #1e88e5 solid 2px !important;
	border-radius: 5px !important;
}
.playlist_input .mdc-text-field:focus-within{
	.mdc-notched-outline.mdc-notched-outline--no-label {
		display: none !important;
	}
}
.play-items-input span.mat-mdc-select-min-line {
	font-size: 14px !important;
}
.media-box{
	overflow: hidden !important;
}
.send-buttons-2.send_butn {
    padding: 10px 20px !important;
    border-radius: 4px;
    opacity: 100%;
}

.repeat-input-items label.mdc-floating-label {
    font-size: 14px;
    color: #b1b1b1 !important;
}
.repeat-input-items input{
	padding: 0 !important;
}
.click_select-itmes {
    padding: 0px 16px;
}
.plicy-lable .mat-mdc-input-element {
    color: rgba(0, 0, 0, .54) !important;
	border-bottom: 1px solid rgba(0, 0, 0, .42) !important;
	padding-bottom: 4px !important;
}
.boxs-background span.mdc-evolution-chip__text-label {
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
}
.boxs-background span.mdc-evolution-chip__action {
    width: 100% !important;
    padding: 0 !important;
}
.boxs-background .mat-chip-ripple {
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    position: absolute;
    pointer-events: none;
    border-radius: inherit;
    overflow: hidden;
}
.mat-mdc-slider .mdc-slider__track--inactive{
	background-color: rgb(0 0 0 / 27%) !important;
    height: 2px !important;
	opacity: 100% !important;
}
.mdc-slider .mdc-slider__track {
    top: 51.2% !important;
}
.mdc-slider .mdc-slider__thumb{
	height: 32px !important;
	width: 32px !important;
}
.mdc-slider .mdc-slider__thumb-knob{
	top: 74% !important;
}
.select_aisa .mat-mdc-form-field-input-control.mat-mdc-form-field-input-control{
	font-size: 13px !important;
	border-bottom: 1px solid rgba(0, 0, 0, .42) !important;
	padding-bottom: 4px !important;
}
.mat-mdc-text-field-wrapper{
	margin-bottom: 2px;
}
.choose_date mat-label {
    font-size: 14px;
    color: #ababab;
	font-weight: 400;
}
.mat-mdc-form-field-error{
	color: #ff3d60 !important;
}
.mat-mdc-form-field-error-wrapper{
	top: 14px !important;
	padding: 0 !important;
}
.volume_slider_button .mdc-slider__thumb-knob {
    border-radius: 50px !important;
}
.orientation-select .mat-mdc-select{
	width: 150px !important;
}
.orientation-select span.mat-mdc-select-min-line.ng-star-inserted {
    font-size: 13px;
}
.orientation-option span{
	font-size: 13px !important;
}
.mdc-list-item--selected.mat-mdc-option-active{
	background: rgba(0,0,0,.04) !important;
}
.my_select_panel .mdc-list-item--selected.mat-mdc-option-active{
	background: rgba(0,0,0,.12) !important;
}
.role-option-chekbox .mdc-form-field.mat-internal-form-field {
    display: inline-block ;
    white-space: nowrap;
    line-height: 1;
}
.date-lable .mdc-text-field--filled .mdc-floating-label--float-above {
    transform: translateY(-52%) scale(0.75) !important;
}
.date-lable button.mdc-icon-button {
    padding-top: 6px !important;
}
.date-lable .mat-mdc-form-field-infix {
    padding-top: 10px;
	padding-bottom: 5px
}
.custom-select-rol .mat-mdc-select-value {
    color: rgba(0, 0, 0, .87) !important;
    font-size: 14px;
    font-family: sans-serif !important;
}
.mat-mdc-radio-button.radio_button-ip.mat-accent.mat-mdc-radio-checked .mdc-radio__inner-circle {
    border-color: #ff4081 !important;
}
.mat-mdc-radio-button.radio_button-ip.mat-accent.mat-mdc-radio-checked .mdc-radio__outer-circle {
    border-color: #ff4081 !important;
}
// .power_time.p-2 {
//     padding-top: 30px !important;
// }
.power_time .mat-mdc-form-field{
	height: fit-content;
}
.mob-power-list td{
	padding: 12px !important;
	line-height: 3.5rem;
}
.power_time span{
	line-height: 1;
}
.mdc-switch.mdc-switch--selected:enabled .mdc-switch__handle::after{
	background: #439be9 !important;
}

#mat-select-2-panel span.mdc-list-item__primary-text {
    color: rgba(0, 0, 0, .87) !important;
    font-size: 14px !important;
    font-family: sans-serif !important;
}
.creat-password .pwd-box {
    justify-content: space-between;
}
.creat-password input.form-control {
    width: 88%;
}
.creat-password mat-icon.mat-icon.notranslate {
    margin: 0 !important;
}
.mat-mdc-tooltip-panel{
	color: #fff !important;
	background: #00000076 !important;
	border-radius: 5px !important;
	
}
.mdc-tooltip__surface.mdc-tooltip__surface-animation{
	max-width: 260px !important;
}
#cdk-overlay-52 .mat-mdc-autocomplete-panel{
	overflow: auto !important;
}
.mat-mdc-autocomplete-panel {
    background: #fff !important;
	overflow: hidden !important;
}
.role-option-input .mat-mdc-form-field-error {
    color: #f44336;
}
.popup-container{
    padding-bottom: 44px !important;
}
.boxs-background .mat-mdc-chip.mat-mdc-standard-chip {
    background-color: inherit !important;
}
.provide_display_section mat-label {
    color: rgba(0, 0, 0, .87) !important;
}
.provide_display_section textarea#mat-input-1::placeholder {
    color: #aeadad !important;
    font-weight: 300 !important;
}
.provide_display_section input::placeholder{
	color: #aeadad !important;
    font-weight: 300 !important;
}
.mat-chip-select span.mdc-evolution-chip__action.mat-mdc-chip-action  {
    background: #f0f0f0 !important;
	border-radius: 20px;
}
.create-user-form {
    row-gap: 18px;
}
.create-user-form .mat-mdc-form-field-error {
    color: #f44336;
}
.role-option-input .mdc-text-field--no-label.mdc-text-field{
	padding: 0px !important;
}
.role-option {
    border: 1px solid #ccc;
    border-radius: 5px;
    padding: 7px;
	color: rgba(0, 0, 0, .42) !important;
	font-size: .9rem !important;
}
.role-option-input .mat-mdc-form-field-subscript-wrapper{
	margin: 0px !important;
}
.role-option-input .mat-mdc-form-field-error-wrapper {
    padding: 0px !important;
}

.display-key-input mat-form-field.mat-mdc-form-field {
    width: 100%;
}
.table-responsive.table-borderless .cdk-virtual-scroll-content-wrapper {
    min-height: 100%;
 }
.chart-4-button{
    padding: 14px 12px !important;
    background-color: inherit !important;
    margin-bottom: 0px !important;
    font-size: 14px;
   
}
.mat-mdc-dialog-container .mdc-dialog__surface{
    overflow: hidden !important;
}
.select-search-5{
	overflow: auto !important;
	min-width: 112px !important;
}
.image-edit-cencel{
	background: #fff !important;
	border-radius: 4px !important;
}
// -----------calendar----------

.mat-input-element {
    font: inherit;
    background: transparent;
    outline: none;
    padding: 0;
    margin: 0;
    width: 100%;
    max-width: 100%;
    vertical-align: bottom;
    text-align: inherit;
    box-sizing: content-box;
}
.aggreate-date-input input.mat-datepicker-input {
    font-size: 14px !important;
	font-weight: 300 !important;
}

span.mat-calendar-body-cell-content.mat-focus-indicator {
    border: none;
}
.aggreate-date-input .mat-mdc-form-field-flex {

    height: 70px !important;
}
.aggreate-date-input input.mat-datepicker-input {
    font-weight: 400 !important;
}
.aggreate-date-input mat-form-field.mat-mdc-form-field {
    height: 54px;
}
.aggreate-date-input label.mdc-floating-label {
    color: #b7b7b7 !important;
}
.edit-image-label .mat-mdc-form-field-type-mat-select.mat-form-field-appearance-fill .mdc-floating-label--float-above {
    max-width: calc(100% / 0 - 0px) !important;
    font-size: 14px !important;
	font-weight: 400 !important;
	color: #b7b7b7 !important;
}
.edit-image-label .mat-mdc-text-field-wrapper .mat-mdc-form-field-flex .mat-mdc-floating-label {
    top: 4px !important;
}
.mat-mdc-select {
    font-size: 14px !important;
}
.mat-mdc-option {
    font-size: 14px !important;
}
.aggreate-date-input .mat-mdc-text-field-wrapper .mat-mdc-form-field-flex .mat-mdc-floating-label {
    top: 22px !important;
}

// span.mat-calendar-body-cell-content.mat-focus-indicator:hover {
// 	background-color: #1e88e520 !important;
// }

.report_type_butn span.mdc-button__label {
    background: #f9f9f9;
    padding: 8px 12px;
    border-radius: 12px;
    font-weight: 400;
    color: #505d69 !important;
}
.report_type_butn button.mat-mdc-menu-trigger {
    box-shadow: none !important;
}

.aggreate-date-input .mat-mdc-text-field-wrapper.mdc-text-field {
    padding: 0 !important;
}
.lifespan-date .mdc-text-field--no-label.mdc-text-field {
    padding: 0 !important;
}
.lifespan-date mat-form-field.mat-mdc-form-field {
    width: 100% ;
}
.lifespan-date button.mdc-icon-button {
    width: fit-content;
}
.lifespan-date svg.mat-datepicker-toggle-default-icon.ng-star-inserted {
    width: 14px;
}
.lifespan-date .single-file.card.rounded.ng-star-inserted {
    padding: 10px 0px 4px 0px !important;
}
// .mat-mdc-autocomplete-panel {
//     overflow: hidden !important;
// }
.form-input button.restart.refresh-icon {
    padding: 10px 14px;
	border-radius: 4px;
}
.mob-btn-position.add-user-btn{
	text-align: right !important;
}
/*
.cstm_plist_example_card.ng-star-inserted {
    overflow: auto;
    height: 32vh;
}*/
.cstm_pl_crd .cdk-drop-list.cstm_plist_crd.example-list {
    overflow: auto;
	max-height: 34vh;
	
}
.display-map{
	height: calc(100% - 20%);
}
.details_titels .mat-mdc-tab-body-wrapper {
    height: 100%;
}
.dashboard-map google-map .map-container {
    height: 100vh !important;
    width: 100% !important;
}
.shadow.rounded{
	box-shadow: 0 2px 1px -1px rgba(0, 0, 0, .2), 0 1px 1px 0 rgba(0, 0, 0, .14), 0 1px 3px 0 rgba(0, 0, 0, .12) !important;
}
.filter-box .inputs {
    border: none !important;
}
.box-border-2 {
    border-top: 1px solid #DEDEDE;
}
.lifespan-date .mat-mdc-form-field-flex{
	border-bottom: 1px solid rgba(0,0,0,.42);
}
.mat-calendar-body-cell-content{
	border-color: transparent;
}
.mat-calendar-body-selected{
	background-color: $primary;
	color: $white;
}
.mat-calendar-body-in-range{
	background-color: rgba($primary, 0.15) !important;
	border-radius: 0;
}
.mat-calendar-body-range-start{
	border-top-left-radius: 50%;
	border-bottom-left-radius: 50%;
}
.mat-calendar-body-range-end {
	border-top-right-radius: 50%;
	border-bottom-right-radius: 50%;
}

.mat-calendar{
	.mat-calendar-body-disabled{
		opacity: .38;

		.mat-calendar-body-cell-content:not(.mat-calendar-body-selected):hover{
			background-color: transparent;
		}
	}
	.mat-calendar-controls{
		.mat-calendar-previous-button, .mat-calendar-next-button{
			height: 40px;
			width: 40px;
			padding: 0 !important;
		}
	}
	.mat-calendar-body-cell-content:not(.mat-calendar-body-selected):hover{
		background-color: #{$primary}20;
	}
}
.ngx-mat-timepicker-tbody{
	.mat-mdc-form-field{
		max-width: 20px;
		.mat-mdc-text-field-wrapper{
			padding: 0 !important;
		}
	}
	.mat-mdc-outlined-button {
		border-color: transparent !important;
	}
}

.img-fluid{
	max-width: 100%;
	height: auto;
}
.image-align-items span.mdc-list-item__primary-text {
    display: flex;
    align-items: center;
}
.playlist-item-select span.mdc-list-item__primary-text {
    color: #000 !important;
}
.mdc-tooltip__surface{
	font-size: 0.610rem !important;
}
.tooltip {
    position: absolute;
    z-index: 1070;
    display: block;
    margin: 0;
    font-family: Nunito,sans-serif;
    font-style: normal;
    font-weight: 400;
    line-height: 1.5;
    text-align: left;
    text-align: start;
    text-decoration: none;
    text-shadow: none;
    text-transform: none;
    letter-spacing: normal;
    word-break: normal;
    white-space: normal;
    word-spacing: normal;
    line-break: auto;
    font-size: .7875rem;
    word-wrap: break-word;
    opacity: 0
}

.mat-mdc-icon-button{
	--mdc-icon-button-state-layer-size: 40px;
	padding: 0 !important;
}
// // // $my-app-theme: mat.define-light-theme($my-app-primary, $my-app-accent);
// @include mat.all-component-themes($my-app-theme);
.pac-container {
	z-index: 10000 !important;
	transform-origin: 50% 20.8px 0px;
    font-size: 14px;
    opacity: 1;
    transform: scaleY(1);
    box-shadow: 0px 2px 4px -1px rgb(0 0 0 / 20%), 0px 4px 5px 0px rgb(0 0 0 / 14%), 0px 1px 10px 0px rgb(0 0 0 / 12%);
	border-radius: 4px;


		.pac-item{
			font-size: inherit;
			padding: 0.5rem !important;
			cursor: pointer;
			&:hover{
				background-color: rgba(0, 0, 0, 0.04);
			}
			
		}
		&:after{
			content: unset !important;
		}
}

.box-accordion {
	.mat-expansion-panel-header {
		height: 40px;
		border-radius: 0;
		border-bottom: 1px solid $gray-100;
		&.mat-expanded{
			background: #efefef;
		}
	}
}

.responsive-img {
    height: 100%;
    width: 100%;
    object-fit: contain;
}

.left-responsive-img {
    height: 100%;
    max-width: 100%;
    object-fit: contain;
}

.mat-mdc-checkbox-checked:not(.mat-checkbox-disabled).mat-accent
.mat-ripple-element,
.mat-checkbox:active:not(.mat-checkbox-disabled).mat-accent
.mat-ripple-element {
	background-color: $primary !important;
	border-color: $primary !important;
}
.mat-checkbox-indeterminate.mat-accent .mat-checkbox-background,
.mat-checkbox-checked.mat-accent .mat-checkbox-background, .mat-mdc-checkbox .mdc-checkbox .mdc-checkbox__native-control:enabled:checked~.mdc-checkbox__background, .mat-mdc-checkbox .mdc-checkbox .mdc-checkbox__native-control:enabled:indeterminate~.mdc-checkbox__background, .mat-mdc-checkbox .mdc-checkbox .mdc-checkbox__native-control[data-indeterminate=true]:enabled~.mdc-checkbox__background {
	background-color: $primary !important;
	border-color: $primary !important;
	color: #fff !important;
}

.restart span{
	color: #cd9d44 !important;
}

.mdc-tab--active {
	opacity: 1 !important;
	span{
		color: $primary !important;
	}
}
.mdc-tab{
	padding: 0 5px;
	opacity: 0.6;
}
.tabs-box .mdc-tab__text-label{
	font-size: 10px;
}

.mat-datepicker-toggle{
	.mat-mdc-icon-button{
		padding: 0;
		--mdc-icon-button-state-layer-size: 30px;
	}
	.mat-mdc-icon-button svg, .mat-mdc-icon-button img{
		width: 16px;
	}
}
.mat-mdc-autocomplete-panel{
	position: absolute !important;
	top: 2px !important;
}
.mat-mdc-checkbox{
	label{
		margin-bottom: 0 !important;

		span{
			font-weight: 500 !important;
			text-wrap: nowrap;
		}
	}
	.mdc-checkbox{
		padding: 0 !important;
		height: 16px;
		width: 16px;
		.mdc-checkbox__background{
			height: 16px;
			width: 16px;
		}

		.mdc-checkbox__native-control{
			width: 100% !important;
			height: 100% !important;
		}

		.mdc-checkbox__background{
			top: 0 !important;
			left: 0 !important;
		}
	}
}

.mat-mdc-unelevated-button.mat-primary,
.mat-mdc-raised-button.mat-primary,
.mat-mdc-fab.mat-primary,
.mat-mdc-mini-fab.mat-primary {
	background: $primary !important;
	border-radius: 4px;
	line-height: 36px;
	color: var(--white) !important;
	height: 40px;
}

.mat-accent .mat-slider-track-fill,
.mat-accent .mat-slider-thumb,
.mat-accent .mat-slider-thumb-label,
.mat-mdc-slide-toggle.mat-checked .mat-slide-toggle-bar {
	background-color: $button-main-color;
}
.mat-mdc-form-field-icon-prefix>.mat-icon, .mat-mdc-form-field-icon-suffix>.mat-icon{
	padding: 0;
}
.mat-mdc-slide-toggle{
	--mdc-theme-primary: #{$primary};
	--mdc-switch-selected-track-color: #{$primary}90;
	--mdc-switch-selected-handle-color: #{$primary};
	--mdc-switch-selected-hover-track-color: #{$primary}90;
	--mdc-switch-selected-hover-handle-color: #{$primary};
	--mdc-switch-selected-focus-track-color: #{$primary}90;
	--mdc-switch-selected-focus-handle-color: #{$primary};
	--mdc-switch-selected-pressed-track-color: #{$primary}90;
	--mdc-switch-selected-pressed-handle-color: #{$primary};
	--mdc-switch-selected-focus-state-layer-color: #{$primary}e0;
	--mdc-switch-selected-hover-state-layer-color: #{$primary}e0;
}
.mat-mdc-slider {
	--mdc-slider-with-tick-marks-inactive-container-color: #{$button-main-color};
	--mat-mdc-slider-hover-ripple-color: #3861b80d;
	--mat-mdc-slider-focus-ripple-color: #3861b833;
	--mdc-slider-focus-handle-color: #{$button-main-color};
	--mdc-slider-handle-color: #{$button-main-color};
	--mdc-slider-label-container-color: #e1e1e1;
	--mdc-slider-label-label-text-color: black;
	--mdc-slider-active-track-color: #{$button-main-color};
	--mdc-slider-inactive-track-color: #{$button-main-color};
	--mat-mdc-slider-value-indicator-opacity: 0.9;
	--mdc-slider-inactive-track-height: 3px;
	--mdc-slider-active-track-height: 3px;
	--mdc-slider-handle-width: 16px;
	--mdc-slider-handle-height: 16px;
	--mdc-slider-handle-elevation: 0px 1px 3px 1px #00000026, 0px 1px 2px 0px #0000004D;

}
.mat-mdc-slider .mdc-slider__value-indicator{
	width: 100px;
	border-radius: 25px;
}
.mat-mdc-slider .mdc-slider__value-indicator-text{
	width: 100%;
	text-align: center;
}
.text-primary {
	color: $button-main-color !important;
	fill: $button-main-color !important;
}

.mdc-text-field--no-label{
	&.mdc-text-field{ padding: 1em !important;}
	
	.mdc-line-ripple::before, .md-line-ripple::after{
		content: unset !important;
	}
}

.mat-mdc-form-field-infix, .mat-mdc-chip-set, .mdc-text-field__input, .mat-mdc-input-element{
	height: 100% !important;
}

.mat-mdc-form-field-input-control.mat-mdc-form-field-input-control{
	font-size: .9rem !important;
}
.mdc-line-ripple::after{
    width: 0 !important;
}
.mat-chip-search {
    background: rgba(20, 75, 157, .12);
    padding: 3px 12px;
    color: #575757;
    font-size: 13px;
}
.search_text_2 .mdc-notched-outline__leading{
display: none !important;
}

.search_text_2 input.mdc-text-field--no-label:not(.mdc-text-field--textarea) .mat-mdc-form-field-input-control.mdc-text-field__input, .mat-mdc-text-field-wrapper .mat-mdc-form-field-input-control::placeholder{
	font-size: 14px !important;
} 

mat-form-field.mat-mdc-form-field.select-nowplaying-box{
	max-width: 100% !important;
}
.edit-user-nav-tab .nav-item .active{
	background-color: #fff;
}
.custom-select-rol .mat-mdc-text-field-wrapper {
    border: 1px solid #ccc;
    border-radius: 5px;
    padding: 8px 0px !important;
}
.check-all-display .mdc-form-field {
    min-width: 100px;
}
.check-all-display .mat-mdc-checkbox {
    margin: 0 !important;
}
.days-list label {
    padding: 0 !important;
}
.table_power{
	padding: 0px 48px;
}

.time_select_option .ng-trigger.ng-trigger-transformPanel{
	min-width: calc(100% + 36px) !important;
	background: #fff;
}
.table_power th {
    padding: 10px 15px;
	background: none !important;
	border: none !important;
}
.days-list .mdc-form-field.mat-internal-form-field {
    display: block !important;
    padding-right: 8px !important;
}
.check-all-display {
    padding-left: 50px !important;
}
.days-list label {
    width: 100%;
}
.power_time label {
    text-overflow: clip !important;
}
.days-list .mat-mdc-checkbox .mdc-checkbox .mdc-checkbox__native-control:enabled:checked ~ .mdc-checkbox__background {
    border-color: #3f51b5 !important;
}
.days-list svg.mdc-checkbox__checkmark {
    background: #3f51b5 !important;
}
.power_button_cancel:hover{
	background: #f5f5f5 !important;
	// border-color: #2c2c2c !important;
	// color: #000000 !important;
}
.my_select_panel {
    min-width:56px;
    background-color: #fff !important;
}
.mob-time {
    display: none !important;
}
.mat-mdc-text-field-wrapper .mat-mdc-form-field-flex .mat-mdc-floating-label{
	top: 5px !important;
}
.profile-edit-titel .edit-user-nav-tab .nav-item .active{
	padding: 0px 150px !important;
}


.details_titels .mat-mdc-tab-labels .mdc-tab.mat-mdc-tab.mat-mdc-focus-indicator {
	min-width: auto !important;
  }
.date-lable .mat-mdc-input-element{
		font-size: 14px !important;
		// padding-top: 16px;

}

.select-list_items mat-pseudo-checkbox.mat-pseudo-checkbox {
    border-color: #A4A4A4 ;
}
.select-list_items .mat-pseudo-checkbox-full.mat-pseudo-checkbox-checked{
	border-color: #1E88E5 !important;
	background: #1E88E5 !important;
	color: #fff !important;
}
.select-chip-input .mat-mdc-text-field-wrapper.mdc-text-field {
    padding: 8px 20px !important;
}

.form-buttons.bg-white.p-3{
	width: 100%;
}
.progress_bar .mat-mdc-progress-bar {
	height: 4px ;
}
.progress_bar .mat-mdc-progress-bar .mdc-linear-progress__bar-inner{
	border: none !important;
	
}
.progress_bar .mat-mdc-progress-bar .mdc-linear-progress__bar.mdc-linear-progress__primary-bar{
	background-color: #7fc88b !important;
}
.info-header .mat-mdc-input-element {
    font-size: 1.125rem !important;
}
 .mdc-text-field--filled .mdc-floating-label--float-above{
	transform: translateY(-80%) scale(0.75) !important;
}
.report-titels .mdc-tab mat-mdc-tab {
    min-width: 160px !important;
}
.Select-displays-chip .mat-mdc-select-arrow-wrapper {
    display: none !important ;
}
.select-option-items .mdc-floating-label.mat-mdc-floating-label {
    font-size: 14px !important;
    color: #9c9b9b !important;
} 
.Preference_inputs .mat-mdc-text-field-wrapper {
    padding: 3px !important;
}
.ip-filter-button:hover{
	background: #efefef !important;
}
.ip-filter-button{
	font-size: 14px;
	font-family: Inter,sans-serif !important;
	font-weight: 500 !important;
}
.filter-box .inputs {
    width: 50% !important;
}
.playlist_time_label .mdc-text-field--filled .mdc-floating-label--float-above {
    transform: translateY(-80%) scale(0.75);
	color: #bdbdbd !important;
} 
.alerts-option .mat-mdc-text-field-wrapper.mdc-text-field  {
    padding: 10px 20px !important;
}	
.web_value .mat-mdc-form-field-error-wrapper {
    padding: 0 !important;
    top: 12px;
}
.web_advanced .mat-mdc-text-field-wrapper {
    padding: 0 !important;
}
.web_advanced .mat-mdc-form-field-infix {
    border-bottom: 1px solid;
}
.web_advanced {
    padding-bottom: 18px;
}
input.playlist_time_option {
    padding: 10px 0px 4px !important;
}
.items_options{
	font-size: 14px !important;
}
.mdc-notched-outline__leading{
	display: none !important;
}
.choose_date input.mat-datepicker-input {
    padding: 0 !important;
}
.play-item-option .mdc-text-field--filled .mdc-floating-label--float-above {
    transform: translateY(-80%) scale(0.75);
}
.mat-mdc-autocomplete-panel.mdc-menu-surface.search-select-section{
	overflow: hidden !important;
}
.provide_display_section .mdc-notched-outline__trailing:focus-visible{
border-color: red !important;
}
.mdc-notched-outline__trailing{
	border: 1px solid rgba(0, 0, 0, .12) !important;
	border-radius: 5px !important;
}

.tags_box {
    max-height: 100px;
    overflow: auto;
	margin-right: 5px;
}
.select_boxs{
	max-height: 250px;
	overflow: auto;
}
.mat-mdc-autocomplete-panel{
   box-shadow: 0 5px 5px rgba(0, 0, 0, 0.1882352941) !important ;
		
}
.logo-data-name td {
    padding-left: 22px;
}

.logo-data-name th {
    padding-left: 22px;
}

.chips-5.field-chip .mat-mdc-standard-chip:not(.mdc-evolution-chip--disabled) .mdc-evolution-chip__text-label{
	font-size: 13px;
	font-weight: 600;
}
mat-form-field.mat-mdc-form-field:focus-visible{
outline-color: 10px red !important;

}

.mdc-text-field--outlined .mdc-notched-outline .mdc-notched-outline__leading{
	width: 0px !important;
}
.click-inputs input.mat-mdc-input-element{
	padding: 20px 10px !important;
	font-size: 14px !important;
}
.click-inputs .mdc-notched-outline__leading{
	display: none !important;
}
.click-inputs .mat-mdc-form-field{
	max-width:100% !important;
}
.date-lable label{
 line-height: 11px !important;
}
.mdc-text-field--filled .mdc-line-ripple::before{
	border-bottom: 1px solid !important	;
}
.date-lable mat-form-field.mat-mdc-form-field {
    max-width: 172px;
}
.nav {
	display: flex;
	flex-wrap: wrap;
	padding-left: 0;
	margin-bottom: 0;
	list-style: none;
}

.mat-accordion .mat-expansion-panel-body{
	padding-left: 0 !important;
}
.table-responsive.table-borderless .table td {
    border: none;
    padding: 0 5px;
}
.table-responsive.table-borderless .table td.dn-mw{
	min-width: 350px;
    max-width: 350px;
}
@media (max-width: 1240px) {
    .table-responsive.table-borderless .table td.dn-mw {
        min-width: 160px;
        max-width: 160px;
    }
}
@media (max-width:1440px) {
	.table-responsive.table-borderless .table td.dn-mw {
        min-width: 250px;
        max-width: 250px;
    }
	.search-input-2 {
		gap: 16px !important;
	}

	.filter-btn-box {
		max-width: 40% !important;
	}
	
}

.theme-light {

	body{
		background: #fafafa;
	}

	.app-preview {
		background: #dddbdb;
	}

	.mat-mdc-table{
		background-color: $white;
	}

	--mdc-dialog-container-color: #f9f9f9;

	.mat-mdc-menu-content{
		background-color: $white;
	}
	
	.mat-mdc-menu-trigger {
		color: #3c3c3c !important;
	}

	.cal-month-view .bg-gray,
	.cal-week-view .cal-day-columns .bg-gray,
	.cal-day-view .bg-gray {
		color: #3c3c3c !important;
	}
	.cal-month-view .cal-event {
		width: 100% !important;
		border-radius: 0;
		line-height: 12px;
		height: 15px;
	}
	.cal-week-view .cal-time-events .cal-event {
		line-height: 10px;
		color: #3c3c3c;
	}
	.cal-day-cell.cal-today {
		color: #1e88e5 !important;
	}
	.cal-week-view .cal-header.cal-today{
		background: rgba($primary, 0.23);
	}

	.mat-mdc-row {
		height: 67px !important;
		&.selected {
			background: $table-row-hover;
			td,.chip-box .mat-mdc-card span{
				color: $button-main-color !important;
		}
		}
	}
	.mat-mdc-outlined-button {
		border: 1px solid currentColor !important;
		padding: 0 15px;
		line-height: 34px;
		border-radius: 4px;
		height: 40px;
	}
	.mat-mdc-row:hover {
		background: $table-row-hover !important;
		td,.chip-box .mat-mdc-card span{
			// color: $white !important;
			color: $button-main-color !important;
		}
	}
	.btn-sidebar-bottom {
		button {
			background-color: $sidebar-dark-menu-item-hover-color;
			color: $sidebar-dark-menu-item-active-color;
		}
	}
	.edit-button_2:hover{
		color: #fff !important;
	}
	.cdk-overlay-pane{
		box-shadow: 0 5px 12px #00000030;
		border-radius: 6px;
		background-color: $white;

	}
	.mat-tab-label-active {
		color: $sidebar-dark-menu-item-active-color;
	}
	.card {
		border-radius: 12px;
		padding-bottom: 0px;
		margin-left: 2px;
		margin-bottom: 2px;
		box-shadow: 0px 2px 8px #0000001a;
	}
	.left-card {
		height: calc(100vh - 65px);
	}
	
	.card-listing {
		height: calc(100vh - 155px);
		overflow: auto;
	}
	.right-sidebar {
		height: calc(100vh - 65px);
	}
	.edit {
		color: $sidebar-dark-menu-item-active-color !important;
	}
	.field-box span.mdc-evolution-chip__text-label.mat-mdc-chip-action-label {
		font-size: 12px !important;
		font-weight: 600 !important;
		font-family: Inter,sans-serif !important;
	}
	.field-box .mat-expansion-panel-header-description {
		max-width: 20% !important;
	}
	
	
	.mat-mdc-tab-group.mat-primary .mat-ink-bar,
	.mat-mdc-tab-nav-bar.mat-primary .mat-ink-bar {
		background-color: $sidebar-dark-menu-item-active-color !important;
	}

	.mat-mdc-menu-panel {
		background: $gray-100;
	}
	.mdc-text-field{
		padding: 0.5em 0 0 0;
	}
	.mat-mdc-form-field-subscript-wrapper{
		position: absolute;
		margin-top: 0.5416666667em;
		top: calc(100% - 1.6666666667em);
		height: 0;
	}
	.mat-mdc-floating-label{
		color: $secondary;
	}
	.mat-mdc-form-field-flex{
		align-items: center;
		height: 100%;
	}
	.mdc-text-field--outlined .mdc-notched-outline{
		color: $gray-500;
	}
	.add-padding {
		padding: 3px 20px !important;
	}
	.add-btn-primary {
		background-color: #1e88e5 !important;
	}

	.table thead th {
		vertical-align: middle;
		background: #f8f8f8;
	}
	.bg-color-tertiary {
		background: #f8f8f8;
	}
	.mat-checkbox-layout {
		margin-bottom: 1px;
	}
	tr.mat-mdc-header-row {
		height: 50px;
	}

	th.mat-mdc-header-cell,
	td.mat-mdc-cell,
	td.mat-mdc-footer-cell {
		vertical-align: middle;
		color: rgba(0,0,0,.87);
	}
	th.mat-mdc-header-cell{
		color: rgba(0,0,0,.54);
		background: #f8f8f8;
	}
	/* TODO(mdc-migration): The following rule targets internal classes of card that may no longer apply for the MDC version. */
	mat-card.mat-mdc-card {
		color: $black;
		cursor: pointer;
	}
	.description-element {
		margin-left: 7rem;
		margin-top: -24px;
		font-size: 10px;
	}

	.status-element {
		margin-left: 25px;
	}
	app-live-status-chip span {
		display: flex;
		align-items: center;
	}
	.chip-box {
		padding: 3px 0px;
		display: inline-block;
		.mat-mdc-card {
			padding: 0px 15px;
			border: none;
			span {
				
				padding: 6px !important;
			}
		}
	}
	.mat-mdc-chip.mat-mdc-standard-chip {
		border-radius: 16px;
		height: 1px;
		background-color: $chip-background ;
		min-height: 20px;
	}
	
	.mat-mdc-standard-chip:not(.mdc-evolution-chip--disabled) .mdc-evolution-chip__text-label{
		color: $chip-color;
	}

	.cstm_pl_crd {
		padding: 15px 0px 0;
		h5 {
			font-size: 10px;
			padding: 0px 42px;
			color: #999;
			margin-bottom: 0;
		}
		h2 {
			padding: 0px 15px;
			padding-bottom: 0px;
			i {
				font-size: 30px;
				padding-right: 0px;
			}
			i.icon-pencil {
				font-size: 18px;
				padding-right: 10px;
				color: #333;
			}
			i.fa-pencil {
				font-size: 18px;
				padding-right: 10px;
				color: #333;
			}
		}
	}
	.grid-btn {
		a {
			color: #a4a4a4;
			&.active {
				i {
					color: $sidebar-dark-menu-item-active-color;
				}
			}
		}
	}
	.mat-mdc-outlined-button {
		border: 1px solid currentColor;
		padding: 0 15px;
		line-height: 34px;
		border-radius: 4px;
		height: 40px;
	}
	.cal-month-view .cal-day-cell.cal-today .cal-day-number {
		background: $button-main-color;
		margin: auto;
	}

	.cstm_plist_crd {
		background: #f8f8f8;
		ul {
			background: #fff;
			border-bottom: 1px solid #f8f8f8;
			li.three {
				h3 {
					color: #3C3C3C;
				}
			}
			li.four {
				p {
					color: #c1c1c1;
					&:hover {
						color: #333;
					}
				}
			}
			li.five {
				i {
					color: red;
				}
			}
		}
	}
	.btn-light {
		background-color: $sidebar-dark-menu-item-hover-color;
		color: $button-main-color;
	}
	.bg-light {
		background: $gray-100 !important;
	}
	.heading {
		color: $button-main-color;
	}
	.playlist_info {
		color: $button-main-color;
	}
	.report-lists {
		fill: $button-main-color;
	}
	.media-desc {
		background-color: $button-main-color;
	}

	.bg-gradient {
		background: linear-gradient(
			180deg,
			rgba(30, 136, 229, 0.56) -17.82%,
			rgba(30, 136, 229, 0.56) -17.82%,
			rgba(30, 136, 229, 0.24) -4.77%,
			rgba(30, 136, 229, 0.09) 4.25%,
			rgba(30, 136, 229, 0.01) 10.37%,
			rgba(30, 136, 229, 0) 17.94%,
			rgba(30, 136, 229, 0) 100%
		);
	}

	// edit user nav
	.edit-user-nav-tab {
		.nav-item {
			width: 50%;
			text-align: center;

			a {
				border: none;
			}

			.active {
				color: #1e88ef;
				border: none;
				border-bottom: 2px solid #1e88ef;
				&:hover {
					border-bottom: 2px solid #1e88ef;
				}
			}
		}
	}
// settings right side bar 
.settings-right-sidebar{
	background: #FAFAFF;
}


// folder input border and button color 
.folder-inputbox{
	input{
		&:focus{
			border: 1px solid $menu-item-active-color !important;
			}
		}
	}
	.bg--ternary{
		background-color: #f8f8f8 !important;
	}

	.display-container.bg--ternary{
		background-color: #DEDEDE !important;
		.grid-items.bg--ternary{
			background-color: #fff !important;
		}
	}
	// bulk edit nav tabs 
	.bulk-tab{
		position: relative;
		top: 20px;
		padding: 0 3rem;
		@media (max-width:$mobile-width){
			padding: 0 1rem;
		}
		li{
			margin-left: auto;
			&:last-child{
				margin-left: 0px;
				a{
				  border-radius: 0px 5px 5px 0px;
				}
			}
			a{
				border:1px solid #C4C4C4;
				border-radius: 5px 0px 0px 5px;
				padding: 5px 15px;
				font-size: 10px;
				color: #C4C4C4;
				text-transform: uppercase;
				&.active,&:hover{
				  background: #E7F4FF !important;
				  border: 1px solid $button-main-color !important;
				  box-sizing: border-box;
				  color: $button-main-color !important;
				}
			}
	
		}
	}
	
	.mat-form-field-appearance-outline.mat-focused .mat-form-field-outline-thick{
		color: $button-main-color !important;
	}
	.search-playlist[disabled]{
		color:rgb(0, 1, 2) !important;
	}
}

.theme-dark {
	// First define some global color variables for your app, these are just for the demo, they can be anything you like
	$bg-dark-primary: #1f262d;
	$bg-dark-secondary: #394046;
	$bg-active: #2c343a;
	$text-color: #d5d6d7;
	// Call the calendar mixin with a sass color map of your theme. Every property is optional.
	// For a list of all variables and how they are used,
	// see https://github.com/mattlewis92/angular-calendar/tree/master/projects/angular-calendar/src/variables.scss
	@include cal-theme(
		(
			bg-primary: rgba(29, 34, 46, 0.2),
			bg-secondary: $bg-dark-secondary,
			weekend-color: indianred,
			bg-active: $bg-active,
			border-color: $border-color,
			gray: $bg-dark-secondary,
			today-bg: $bg-dark-secondary,
			event-color-primary: $bg-dark-secondary,
		)
	);
	.month-content{
		color: unset !important;
	}

	--white: #424242;

	.bg-light-primary{
		background-color: $gray-900 !important;
	}

	.split-screen{
		background: $gray-800 !important;
	}

	.mdc-tooltip__surface{
		background-color: #181818;
		color: white;
		border: 1px solid $primary;
	}
	.add-fields-tearsheet{
		background: #000 !important;
		color-scheme: #fff !important;
	}
.tearsheet-titel h2{
	color: #fff !important;
}
.Kittens h3 {
    color: #fff !important;
}
.input-select label {
    color: #ffffff !important;
}
	body {
		font-family: "Inter", sans-serif !important;
		font-size: .9rem;
		font-weight: 400;
		line-height: 1.5;
		color: #505d69;
		text-align: left;
		background-color: $black;
	}
	.edit-button_2:hover{
		color: #fff !important;
	}
	.form-input .logo-lable {
		background: #f3f2f0 !important;
		color: #181818 !important;
	}
	td.mat-mdc-cell{
		color: #fff !important;
	}
	#mat-select-2-panel span.mdc-list-item__primary-text{
		color: #fff !important;
	}
	.mat-mdc-slider .mdc-slider__track--inactive{
		background-color: #878787 !important;
	}
	.add-fields-tearsheet {
		background: #fff;
	}
	.none-selected{
		color: $gray-500;
	}

	.add-btn-primary *{
		color: white !important;
	}

	.box-accordion .mat-expansion-panel-header{
		border-color: $gray-800;
	}

	.mat-calendar-table{
		th, td{
			color: $gray-400;
		}
	}
	.mdc-switch.mdc-switch--selected:enabled .mdc-switch__handle::after{
		background: #ff4081 !important;
	}
	.mat-mdc-menu-content, .mdc-icon-button{
		color: $gray-400;
	}
	.mat-mdc-menu-panel {
		background: $gray-200 !important;
	}
	.mdc-menu-surface, .cdk-overlay-pane{
		background-color: $gray-900 !important;
	}
	.plicy-lable .mat-mdc-input-element{
		color: #fff !important;
	}
	.boxs-background .mat-mdc-chip.mat-mdc-standard-chip {
		background: none !important;
	}
	.boxs-background span.mdc-evolution-chip__text-label{
		color: #000 !important;
	}
	.boxs-background .mdc-data-table__cell span {
		color: #3c3c3c !important;
	}
	.boxs-background .custom-mat-chip {
		padding: 6px 16px !important;
	}	
	.playlist-item-select span.mdc-list-item__primary-text{
		color: #fff !important;
	}
	.mdc-notched-outline__trailing {
		border: 1px solid rgba(255, 255, 255, 0.12) !important;
		border-radius: 5px !important;
	}
	.remove-clear-icon::after{
		background: none !important;
	}
	.mat-mdc-form-field-input-control.mat-mdc-form-field-input-control.replace-input{
		color: #fff !important;
	}
	.cdk-overlay-pane{
		border-radius: 6px;
	}
	.app-preview {
		background: #000;
	}
	.field-box{
		background-color: #141414 !important;
	}
	.filed-form .chip-sections:hover{
		background: #141414 !important;
	}
	.mdc-text-field{
		padding: 0.5em 0 0 0;
	}
	.mat-mdc-form-field-subscript-wrapper{
		position: absolute;
		margin-top: 0.5416666667em;
		top: calc(100% - 1.6666666667em);
		height: 0;
	}

	.modal-dailog-boxes,.custom-dailog .title, .logo-lable, .mat-mdc-dialog-container .mdc-dialog__surface{
		background-color: $gray-200 !important;
	}
	.display-list-wrapper .heading{
		background-color: $gray-900 !important;
	}
	.title{
		background: transparent !important;
	}
	.cal-time-label-column,
	.cal-hour-odd {
		background-color: rgb(32, 32, 32) !important;
	}

	.cal-week-view .cal-time-events .cal-event {
		color: #a4a4a4;
	}

	.cal-month-view .cal-event {
		width: 100% !important;
		border-radius: 0;
		line-height: 12px;
		height: 15px;
	}
	
	.btn-outline-primary {
		color: $button-main-color;
		border: 1px solid $button-main-color !important;
		&:hover {
			color: white;
			background-color: $button-main-color;
		}
	}
	.cal-day-cell.cal-today {
		color: #1e88e5 !important;
	}
	.cal-week-view .cal-header.cal-today{
		background: rgba($primary, 0.23);
	}

	.icon-bg{
		background: $gray-800 !important;
		color: $gray-400 !important;
		
		svg path{
			fill: $gray-400 !important;
		}
	}
	
	.mat-mdc-row {
		height: 67px !important;
		&.selected {
			background: rgba($primary, 0.22);
			background: $table-row-hover;
			td,.chip-box .mat-mdc-card span{
				color: $button-main-color !important;
		}
		}
	}
	.mat-mdc-chip{
		height: fit-content;
	}
	.mat-mdc-row:hover {
		td,.chip-box .mat-mdc-card span{
			color: #1e88e5 !important;
		}
	}
	.mat-mdc-checkbox-checked:not(.mat-checkbox-disabled).mat-accent
	.mat-ripple-element,
	.mat-checkbox:active:not(.mat-checkbox-disabled).mat-accent
	.mat-ripple-element {
		background: $primary !important;
	}
	.mat-checkbox-indeterminate.mat-accent .mat-checkbox-background,
	.mat-checkbox-checked.mat-accent .mat-checkbox-background {
		background: $primary !important;
	}
	.mat-mdc-unelevated-button.mat-primary,
	.mat-mdc-raised-button.mat-primary,
	.mat-mdc-fab.mat-primary,
	.mat-mdc-mini-fab.mat-primary {
		background: $button-main-color !important;
		border-radius: 4px;
		line-height: 36px;
	}
	
	.mat-accent .mat-slider-track-fill,
	.mat-accent .mat-slider-thumb,
	.mat-accent .mat-slider-thumb-label,
	.mat-mdc-slide-toggle.mat-checked .mat-slide-toggle-bar {
		background-color: $button-main-color;
	}
	.mat-mdc-slide-toggle{
		--mdc-switch-selected-track-color: $primary;
		--mdc-switch-selected-icon-color: $primary;
	}
	.text-primary {
		color: $button-main-color !important;
		fill: $button-main-color !important;
	}
	.btn-sidebar-bottom {
		button {
			background-color: $sidebar-dark-menu-item-hover-color;
			color: $sidebar-dark-menu-item-active-color;
		}
	}
	.card {
		border-radius: 12px;
		padding-bottom: 0px;
		margin-bottom: 0px;
	}
	.left-card {
		height: calc(100vh - 65px);
	}
	.card-listing {
		height: calc(100vh - 155px);
		overflow: auto;
	}
	.right-sidebar {
		height: calc(100vh - 65px);
	}
	.edit {
		color: $sidebar-dark-menu-item-active-color !important;
	}
	.mat-mdc-tab-group.mat-primary .mat-ink-bar,
	.mat-mdc-tab-nav-bar.mat-primary .mat-ink-bar {
		background-color: $sidebar-dark-menu-item-active-color !important;
	}
	
	.add-padding {
		padding: 3px 20px !important;
	}
	.add-btn-primary {
		background-color: #1e88e5 !important;
	}
	
	.table thead th {
		vertical-align: middle;
	}
	.bg-color-tertiary {
		background: #f8f8f8;
	}
	.mat-checkbox-layout {
		margin-bottom: 1px;
	}
	tr.mat-mdc-header-row {
		height: 50px;
	}
	
	th.mat-mdc-header-cell,
	td.mat-mdc-cell,
	td.mat-mdc-footer-cell {
		vertical-align: middle;
	}
	/* TODO(mdc-migration): The following rule targets internal classes of card that may no longer apply for the MDC version. */
	mat-card.mat-mdc-card {
		cursor: pointer;
		color: $gray-400 !important;
	}
	.description-element {
		margin-left: 7rem;
		margin-top: -24px;
		font-size: 10px;
	}
	
	.status-element {
		margin-left: 25px;
	}
	app-live-status-chip span {
		display: flex;
		align-items: center;
	}
	.chip-box {
		padding: 3px 0px;
		display: inline-block;
		.mat-mdc-card {
			padding: 0px 15px;
			border: none !important;
			span {
				padding: 6px !important;
			}
		}
	}
	.mat-mdc-chip.mat-mdc-standard-chip {
		background-color: rgba(133, 182, 255, .4) !important;
		color: #f2f2f2;
		min-height: 20px;
		border-radius: 16px;
	}
	.mat-expansion-panel-header-title .mat-chip {
		padding: 3px;
	}
	.mat-expansion-panel-body .mat-chip {
		padding: 3px;
	}	
	.title h6{
		color: #ced4da !important; 
	}
	.title p{
		color: #ced4da !important;
	}
	.field-form h1{
		color: #ced4da !important;
	}
	.form-field input{
		margin-bottom: 1px;
	}
	.form-field label{
		color: #ced4da !important;
	}
	h2.values {
		color:  #ced4da !important;;
	}
	.zee-tooltip .zee-tooltip-text{
		color: #1e88e5 !important;
	}
	.mat-expansion-panel-header-title span.mdc-evolution-chip__action{
		color: #ffffff !important;
	}
	.mat-expansion-panel-body span.mdc-evolution-chip__action{
		color: #ffffff !important;
	}
	.mdc-tooltip--shown .mdc-tooltip__surface-animation {
		border: none;
	}
	
	.mat-mdc-tooltip-panel{
		background-color: #80808085 !important;
	}
	.cstm_pl_crd {
		padding: 15px 0px 0;
		h5 {
			font-size: 10px;
			padding: 0px 42px;
			color: #999;
			margin-bottom: 0;
		}
		h2 {
			padding: 0px 15px;
			padding-bottom: 0px;
			i {
				font-size: 30px;
				padding-right: 0px;
			}
			i.icon-pencil {
				font-size: 18px;
				padding-right: 10px;
				color: #333;
			}
			i.fa-pencil {
				font-size: 18px;
				padding-right: 10px;
				color: #333;
			}
		}
	}
	.grid-btn {
		a {
			color: #a4a4a4;
			
			&.active {
				i {
					color: $sidebar-dark-menu-item-active-color;
				}
			}
		}
	}
	
	.mat-mdc-outlined-button {
		border: 1px solid currentColor !important;
		padding: 0 15px;
		line-height: 34px;
		border-radius: 4px;
		height: 40px;
	}
	.cal-month-view .cal-day-cell.cal-today .cal-day-number {
		background: $button-main-color;
	}
	
	.cstm_plist_crd {
		ul {
			background: #141414;
			border-bottom: 1px solid #252b3b;
			li.three {
				h3 {
					color: #fff;
				}
			}
			li.four {
				p {
					color: #c1c1c1;
					&:hover {
						color: #333;
					}
				}
			}
			li.five {
				i {
					color: red;
				}
			}
		}
	}
	
	.btn-light {
		background-color: $sidebar-dark-menu-item-hover-color;
		color: $button-main-color;
	}
	
	.heading {
		color: $button-main-color;
	}
	.playlist_info {
		color: $button-main-color;
	}
	.report-lists {
		fill: $button-main-color;
	}
	.media-desc {
		background-color: $button-main-color;
	}
	.bg-gradient {
		background: linear-gradient(
			180deg,
			rgba(30, 136, 229, 0.56) -17.82%,
			rgba(30, 136, 229, 0.56) -17.82%,
			rgba(30, 136, 229, 0.24) -4.77%,
			rgba(30, 136, 229, 0.09) 4.25%,
			rgba(30, 136, 229, 0.01) 10.37%,
			rgba(30, 136, 229, 0) 17.94%,
			rgba(30, 136, 229, 0) 100%
			);
		}
		
		// edit user nav
		.edit-user-nav-tab {
			.nav-item {
				width: 50%;
				text-align: center;
				
				a {
					border: none;
				}
				
				.active {
					color: #1e88ef;
					border: none;
					border-bottom: 2px solid #1e88ef;
					&:hover {
						border-bottom: 2px solid #1e88ef;
					}
				}
			}
		}
	// settings right side bar 
	.settings-right-sidebar{
		background: #181818;
	}
	
	// folder input border and button color 
	.folder-inputbox{
		input{
				&:focus{
					border: 1px solid $menu-item-active-color !important;
				}
			}
		}
		.bg--ternary{
			background-color: #212121 !important;
		}

		// bulk edit nav tabs 
	.bulk-tab{
		position: relative;
		top: 20px;
		padding: 0 3rem;
		li{
			margin-left: auto;
			&:last-child{
				margin-left: 0px;
				a{
				  border-radius: 0px 5px 5px 0px;
				}
			}
			a{
				border:1px solid #C4C4C4;
				border-radius: 5px 0px 0px 5px;
				padding: 5px 15px;
				font-size: 10px;
				color: #C4C4C4;
				text-transform: uppercase;
				&.active,&:hover{
				  background: rgba(157, 195, 227, 0.1) !important;
				  box-sizing: border-box;
				}
			}
	
		}
	}
	
	.mat-form-field-appearance-outline.mat-focused .mat-form-field-outline-thick{
		color: $button-main-color !important;
	}
	
	// mat progress bar 
	
	.mat-progress-bar-buffer {
		background: transparent;
	}
	.mat-progress-bar-background{
		fill: transparent;
	}

	.ngx-charts .label{
		fill: $white;
	}
	.form-input, input, .form-control {
		color: $white !important;
		background-color: transparent;
	}

	.app-add-new-display-container{
		background-color: $gray-200;

	}
	.app-add-new-display-container  .key-input{
			background-color: #424242;
	}
	.details-top-edit{
		background: rgba(29, 34, 46, 0.2);
	}

	.social-box-container.active{
		box-shadow: 0 0 0 5px #ffffff25 !important;
	}
	
}
	
.theme-maroon {

	body {
		font-family: "Inter", sans-serif !important;
	}

	.cal-month-view .bg-gray,
	.cal-week-view .cal-day-columns .bg-gray,
	.cal-day-view .bg-gray {
		color: #a4a4a4 !important;
	}
	.cal-month-view .cal-event {
		width: 100% !important;
		border-radius: 0;
		line-height: 12px;
		height: 15px;
	}

	.cal-day-cell.cal-today {
		color: $primary !important;
	}
	.cal-week-view .cal-header.cal-today{
		background: rgba($primary, 0.23);
	}
	.schedule-box .btn-group .active {
		border-bottom: 2px solid $button-main-color !important;
	}

	.date {
		background: $sidebar-dark-menu-item-hover-color !important;
	}

	.mat-mdc-row {
		height: 67px !important;
		&.selected {
			td,.chip-box .mat-mdc-card span{
				color: $button-main-color !important;
		}
		}
	}
	.mat-mdc-row:hover {
		td,.chip-box .mat-mdc-card span{
			color: $button-main-color !important;
		}
	}
	.mat-mdc-checkbox-checked:not(.mat-checkbox-disabled).mat-accent
		.mat-ripple-element,
	.mat-checkbox:active:not(.mat-checkbox-disabled).mat-accent
		.mat-ripple-element {
		background: $primary !important;
	}
	.mat-checkbox-indeterminate.mat-accent .mat-checkbox-background,
	.mat-checkbox-checked.mat-accent .mat-checkbox-background {
		background: $primary !important;
	}
	.mat-mdc-unelevated-button.mat-primary,
	.mat-mdc-raised-button.mat-primary,
	.mat-mdc-fab.mat-primary,
	.mat-mdc-mini-fab.mat-primary {
		border-radius: 4px;
		line-height: 36px;
	}
	.btn-sidebar-bottom {
		button {
			background-color: $sidebar-dark-menu-item-hover-color;
			color: $sidebar-dark-menu-item-active-color;
		}
	}
	
	.mat-accent .mat-slider-track-fill,
	.mat-accent .mat-slider-thumb,
	.mat-accent .mat-slider-thumb-label,
	.mat-mdc-slide-toggle.mat-checked .mat-slide-toggle-bar {
		background-color: $button-main-color;
	}
	
	.mat-mdc-slide-toggle.mat-checked .mat-slide-toggle-thumb {
		background-color: lighten($button-main-color, 8%);
	}
	.text-primary {
		color: $button-main-color !important;
		fill: $button-main-color !important;
	}
	.card {
		border-radius: 12px;
		padding-bottom: 0px;
		margin-bottom: 0px;
	}
	.left-card {
		height: calc(100vh - 65px);
	}
	.card-listing {
		height: calc(100vh - 155px);
		overflow: auto;
	}
	.right-sidebar {
		height: calc(100vh - 65px);
	}
	.mat-tab-label-active {
		color: $sidebar-dark-menu-item-active-color;
	}
	.edit {
		color: $sidebar-dark-menu-item-active-color;
	}
	.mat-mdc-tab-group.mat-primary .mat-ink-bar,
	.mat-mdc-tab-nav-bar.mat-primary .mat-ink-bar {
		background-color: $sidebar-dark-menu-item-active-color !important;
	}

	.add-padding {
		padding: 3px 20px !important;
	}
	.add-btn-primary {
		background-color: #1e88e5 !important;
	}
	.mat-mdc-unelevated-button.mat-primary, .mat-mdc-raised-button.mat-primary, .mat-mdc-fab.mat-primary, .mat-mdc-mini-fab.mat-primary{
		background-color: #9f4f55 !important;
	}
	.grid-btn a.active i {
		color: #9f4f55 !important;
	}
	.table thead th {
		vertical-align: middle;
	}
	body {
		background-color: #f3f2f0 !important;
	}
	.bg-color-tertiary {
		background: #f8f8f8 ;
	}
	.mat-checkbox-layout {
		margin-bottom: 1px;
	}
	tr.mat-mdc-header-row {
		height: 50px;
	}

	th.mat-mdc-header-cell,
	td.mat-mdc-cell,
	td.mat-mdc-footer-cell {
		vertical-align: middle;
		padding: 0;
	}
	/* TODO(mdc-migration): The following rule targets internal classes of card that may no longer apply for the MDC version. */
	mat-card.mat-mdc-card {
		cursor: pointer;
	}
	.description-element {
		margin-left: 7rem;
		margin-top: -24px;
		font-size: 10px;
	}

	.status-element {
		margin-left: 25px;
	}
	app-live-status-chip span {
		display: flex;
		align-items: center;
	}
	.chip-box {
		padding: 3px 0px;
		display: inline-block;
		.mat-mdc-card {
			padding: 0px 15px;
			border: none;
			span {
				padding: 6px !important;
			}
		}
	}
	.mat-mdc-chip.mat-mdc-standard-chip {
		min-height: 20px;
	}

	.cstm_pl_crd {
		padding: 15px 0px 0;
		h5 {
			font-size: 10px;
			padding: 0px 42px;
			color: #999;
			margin-bottom: 0;
		}
		h2 {
			padding: 0px 15px;
			padding-bottom: 0px;
			i {
				font-size: 30px;
				padding-right: 0px;
			}
			i.icon-pencil {
				font-size: 18px;
				padding-right: 10px;
				color: #333;
			}
			i.fa-pencil {
				font-size: 18px;
				padding-right: 10px;
				color: #333;
			}
		}
	}
	.grid-btn {
		a {
			color: #a4a4a4;

			&.active {
				i {
					color: $sidebar-dark-menu-item-active-color;
				}
			}
		}
	}
	.mat-mdc-outlined-button {
		border: 1px solid currentColor !important;
		padding: 0 15px;
		line-height: 34px;
		border-radius: 4px;
		height: 40px;
	}
	.cal-month-view .cal-day-cell.cal-today .cal-day-number {
		background: $button-main-color;
	}
	.cstm_plist_crd {
		background: #f8f8f8;
		ul {
			background: #fff;
			border-bottom: 1px solid #f8f8f8;
			li.three {
				h3 {
					color: #3C3C3C;
				}
			}
			li.four {
				p {
					color: #c1c1c1;
					&:hover {
						color: #333;
					}
				}
			}
			li.five {
				i {
					color: red;
				}
			}
		}
	}

	.btn-light {
		background-color: $sidebar-dark-menu-item-hover-color;
		color: $button-main-color;
	}
	.bg-light {
		background: $gray-100 !important;
	}
	.mat-mdc-dialog-container {
		background-color: rgba(153, 30, 42, 0.03)  !important;
	}
	.mat-mdc-dialog-container .mdc-dialog__surface{
		background: none;
	}
	.app-preview {
		background: rgba(153, 30, 42, 0.03) !important;
	}
	.add_new-display{
		background: #f8f9fa !important;
	}
	.mat-mdc-form-field-subscript-wrapper {
		position: absolute;
		margin-top: 0.5416666667em;
		top: calc(100% - 1.6666666667em);
		height: 0;
	}
	.cdk-overlay-pane{
		background: #fff;
	}
	.heading {
		color: $button-main-color;
	}
	 a {
		color: #9f4f55;
		text-decoration: none;
		background-color: transparent;
	}
	.btn-primary {
		color: #fff !important;
		background-color: #9f4f55 !important;
		border-color: #9f4f55 !important;
	}
	 .mat-row:hover {
		background: rgba(159, 79, 85, .22);
	}
	.mat-mdc-row:hover , .mat-mdc-row:hover:hover td {
		color: #9f4f55 !important;
		
	}
	.btn-outline-primary {
		color: #9f4f55;
		border-color: #9f4f55;
	}
	.btn-outline-primary:hover {
		color: #fff;
		background-color: #9f4f55;
	}
	 .mat-header-cell {
		color: rgba(0, 0, 0, .54);
	}
	.mat-mdc-row.selected{
		background: rgba(159,79,85,.22) !important;
	} 
	 .mat-mdc-row.selected td,  .mat-mdc-row.selected .chip-box .mat-mdc-card span {
		color: #9f4f55 !important;
	}
	#sidebar-menu ul li a:hover i, #sidebar-menu ul li a:hover span, #sidebar-menu ul li a.selected i, #sidebar-menu ul li a.selected span {
		color: #9f4f55 !important;
	}
	.vertical-collpsed .vertical-menu #sidebar-menu > ul > li:hover > a i{
		color: #9f4f55 !important;
	}
	.bg-primary{
		background-color: #9f4f55 !important;
	}
	.mat-mdc-slider .mdc-slider__track--active_fill{
		background-color: #9f4f55 !important;
		border-color: #9f4f55 !important;
	}
	.progress_bar .mat-mdc-progress-bar .mdc-linear-progress__bar.mdc-linear-progress__primary-bar{
		background-color: #9f4f55 !important;
	}
	.playlist_input .mdc-text-field:focus-within {
		outline: #9f4f55 solid 2px !important;
		
	}
	.schedule-box .btn-group .active {
		border-bottom: 2px solid #1E88E5 !important;
	}
	.nav-pills  .settings-nav-item.nav-link.active span {
		color: #9f4f55 !important;
	}
	.mdc-tab--active span{
		color: #9f4f55 !important;
	}
	.mat-mdc-slider .mdc-slider__thumb-knob {
		background-color:#9f4f55 !important;
		border-color: #9f4f55 !important;
	}
	 .text-primary {
		color: #9f4f55 !important;
		fill: #9f4f55 !important;
	}
	.vertical-collpsed .vertical-menu #sidebar-menu > ul > li:hover > a {
		color: #9f4f55 !important;
	}
	.mat-checkbox-indeterminate.mat-accent .mat-checkbox-background, .mat-checkbox-checked.mat-accent .mat-checkbox-background, .mat-mdc-checkbox .mdc-checkbox .mdc-checkbox__native-control:enabled:checked ~ .mdc-checkbox__background, .mat-mdc-checkbox .mdc-checkbox .mdc-checkbox__native-control:enabled:indeterminate ~ .mdc-checkbox__background, .mat-mdc-checkbox .mdc-checkbox .mdc-checkbox__native-control[data-indeterminate=true]:enabled ~ .mdc-checkbox__background{
		background-color: #9f4f55 !important;
		border-color:#9f4f55 !important;
	}
	 .edit, .mat-tab-label-active {
		color: #9f4f55;
	}
	.mat-mdc-row:hover{
		background: rgba(159,79,85,.22) !important;
	}
	.playlist_info {
		color: $button-main-color;
	}
	.report-lists {
		fill: $button-main-color;
	}
	.media-desc {
		background-color: $button-main-color;
	}
	.bg-gradient {
		background: linear-gradient(
			180deg,
			#fff8f8 0%,
			rgba(255, 255, 255, 0) 41.51%
		);
	}

	
	// edit user nav
	.edit-user-nav-tab {
		.nav-item {
			width: 50%;
			text-align: center;

			a {
				border: none;
			}

			.active {
				border: none;
				&:hover {
					border-bottom: 2px solid $button-main-color !important;
				}
			}
		}
	}
	
	.mat-form-field-appearance-outline.mat-focused .mat-form-field-outline-thick{
		color: $button-main-color !important;
	}
	// settings right side bar 
	.settings-right-sidebar{
		background: #9f4f540a;
	}
	.mat-mdc-chip.mat-mdc-standard-chip {
		border-radius: 16px;
		height: 1px;
		background-color: rgba(20, 75, 157, 0.12);
		min-height: 20px;
	}
	// folder input border and button color 
.folder-inputbox{
    input{
		&:focus{
        	border: 1px solid $menu-item-active-color !important;
			}
    	}
	}
	.bg--ternary{
		background-color: rgba(153, 30, 42, 0.03) !important;
	}

	// bulk edit nav tabs 
	.bulk-tab{
		position: relative;
		top: 20px;
		padding: 0 3rem;
		li{
			margin-left: auto;
			&:last-child{
				margin-left: 0px;
				a{
				  border-radius: 0px 5px 5px 0px;
				}
			}
			a{
				border:1px solid #C4C4C4;
				border-radius: 5px 0px 0px 5px;
				padding: 5px 15px;
				font-size: 10px;
				color: #C4C4C4;
				text-transform: uppercase;
				&.active,&:hover{
				  box-sizing: border-box;
				}
			}
	
		}
	}
	// mat progress bar 
.mat-progress-bar-buffer {
    background: transparent;
}
.mat-progress-bar-background{
    fill: transparent;
}

.mob-rows.selected{
	background: $table-row-hover !important;
	border-left: 4px solid $button-main-color;
}
.settings-nav-item.nav-link.active{
	background-color: transparent !important;
	span{
		color: $menu-item-active-color !important;
	}
}
.fas.fa-chevron-left{
	color: $button-main-color;
}
}

html,
body {
	height: 100%;
}
body {
	margin: 0;
	font-family: "Inter", sans-serif !important;
	font-size: .9rem;
    font-weight: 400;
    line-height: 1.5;
    color: #505d69;
    text-align: left;
}
.logo-dark {
	display: none;
}
body[data-sidebar="dark"] {
	.logo-dark {
		display: block;
	}

	.logo-light {
		display: none;
	}
}

* {
	margin: 0px;
	padding: 0px;
	box-sizing: border-box;
	-webkit-box-sizing: border-box;
	-moz-box-sizing: border-box;
}

.add {
	text-transform: uppercase !important;
}
.delete {
	color: #ff5556 !important;
}

.cursor{
	cursor: pointer;
}

.mat-mdc-menu-content{
	.mat-mdc-menu-item{
		&:hover{
			background-color: #{$primary}17;
		}
	}
}
.mat-mdc-menu-content button.mat-mdc-menu-item.mat-mdc-focus-indicator {
	padding: 0px 16px !important;
	gap: 18px ;
}
.input_value_select mat-form-field {
padding: 0 !important;}
span.mat-mdc-menu-item-text {
    font-size: 14px !important;
	
}
.mat-chip-select{
	margin: 10px 5px 0px 0px!important;
}
.mat-chip-select .cut-text {
    max-width: 90px;
	font-weight: 500;
	color: #575757;
}
.mat-chip-select span.mdc-evolution-chip__text-label.mat-mdc-chip-action-label {
    display: flex !important;
    align-items: center !important;
}
.apply-butn-2 span.mdc-button__label {
    line-height: 0;
}
.mat-mdc-option.mdc-list-item:hover {
    background: rgba(0, 0, 0, .05) !important;
}
button.mat-mdc-menu-item.mat-mdc-focus-indicator:hover {
    background: rgba(0, 0, 0, .04) !important;
}
.apply-butn-2{
	opacity: 100% !important;
	cursor: pointer !important;
}
.mat-mdc-standard-chip .mdc-evolution-chip__action--primary:before{
	border: none !important;
}
.mat-mdc-menu-panel{
background-color: #fff !important;
border-radius: 5px !important;
}
.mat-mdc-option {
	min-height: 40px !important;
	&:hover{
		background-color: #{$black}09;
	}
	&.mdc-list-item--selected{
		background-color: #{$black}17;
	}
	.mat-pseudo-checkbox-minimal{
		display: none;
	}
	.mdc-list-item__primary-text{
		width: 100%;
		white-space: nowrap !important;
		text-overflow: ellipsis;
		overflow: hidden;
		font-size: 14px;
		letter-spacing: 0 !important;
	}

}

// mat progress bar 
.mat-progress-bar-buffer {
    background: transparent;
}
.mat-progress-bar-background{
    fill: transparent;
}

.infinite-scroll-list{
	height: calc(100vh - 215px);
	overflow: hidden scroll;
}

.inside-folder-data .infinite-scroll-list{
	height: calc(100vh - 290px) !important;
}
td.mat-mdc-cell {
	border-bottom-style: none;
	padding-top: 5px !important;
	padding-bottom: 5px !important;
}
th.mat-mdc-header-cell {
	border-bottom-style: none;
	font-weight: 600;
	font-size: 12px;
	color: #0000008a;
}
.highlight {
	background: #42a948;
}

.mat-mdc-chip {
	padding: auto;
	box-sizing: border-box;
	font-size: small;
}
.mat-mdc-standard-chip {
	min-height: 20px;
}

/*detail page*/
.nav-spacer {
	flex: 1 1 auto;
}
.details-toolbar {
	display: flex;
	justify-content: center;
}
.media-details-top {
	min-height: 20vh;
	height: 22%;
	background-color: #f9f9f9;
}
.media-detais-top-container {
	padding-top: 1.5rem;
	padding-left: 1.5rem;
	padding-right: 1.5rem;
}
.media-details-bottom {
	width: 100%;
	height: 78%;
}
.media-details-bottom-holder {
	height: 100%;
	display: flex;
	flex-direction: column;
}
.media-details-bottom-bottom {
	border-top: grey;
	height: calc(100% - 220px)
}
.details-top {
	height: 34%;
	width: 100%;
	background-color: #f9f9f9;
}
.details-top-content {
	padding: 30px;
}
.details-bottom {
	top: 34%;
	width: 100%;
	height: 66%;
}
.details-bottom-holder {
    display: flex;
    flex-direction: column;
    height: 100%;
}
.details-bottom-top {
    flex-grow: 1;
	padding: 20px;
	border-bottom: 1px solid #e8e8e8;
}
.details-bottom-bottom {
    flex-grow: 4;
	border-top: grey;
    height: calc(100% - 141px);
}
.mat-icon {
	vertical-align: middle;
}
.details-top-edit {
	min-height: 40vh;
	background-color: #f9f9f9;
}
.details-top {
	min-height: 30vh;
	background-color: #f9f9f9;
}
.details-top-content {
	padding: 30px;
}
.media-list {
	max-height: 50vh;
	overflow-y: auto;
}
.mat-icon {
	vertical-align: middle;
}
.none-selected {
	margin: 0;
	display: flex;
	align-items: center;
	justify-content: center;
	height: 30vh;
	.mat-icon{ margin-right: 0.2em; }
}
.details-bottom, .media-details-bottom {
	.none-selected {
		padding: 45px;
		height: calc(70vh - 120px);
	}
}
.none-selected-text {
	border-radius: 0px;
	background-color: #f9f9f9;
	padding: 20px;
}

.status-title {
	margin-left: 0px;
}

.mat-mdc-chip {
	padding: auto;
	box-sizing: border-box;
	font-size: small;
}

.mat-mdc-standard-chip {
	min-height: 20px;
}
.status {
	border-left: solid 1px #a4a4a4;
}
.status-title {
	margin-left: 0px;
}


.select-nowplaying-box .mat-form-field-flex {
	background-color: transparent;
}
.navbar-brand-box {
	padding: 0 1rem;
}
.vertical-collpsed .vertical-menu {
	z-index: 9999;
}
#sidebar-menu {
	padding: 0 0 15px 0px;
}
.mat-mdc-standard-chip.mdc-evolution-chip--with-primary-graphic .mdc-evolution-chip__action--primary::before{
	border: none !important;
}
.mat-standard-chip{
	border-radius: 16px;
}
.mat-accent .mat-slider-track-fill,
.mat-accent .mat-slider-thumb,
.mat-accent .mat-slider-thumb-label,
.mat-mdc-slide-toggle.mat-checked .mat-slide-toggle-bar {
	background-color: rgba(30, 136, 229, 0.22);
}
.mat-mdc-tab-label-container{
	border-bottom: none !important;
}
.mat-mdc-tab-labels{
	justify-content: space-around;
}
.mat-slider .mdc-slider__thumb-knob{
	border-radius: 50px !important;
}
.mat-mdc-tab-group.mat-mdc-tab-group-stretch-tabs>.mat-mdc-tab-header .mat-mdc-tab{
	flex-grow: 0 !important;
}
.mat-mdc-tab-header {
	border-bottom: none !important;
	border-top: gray;
}
.mdc-tab.mat-mdc-tab.mat-mdc-focus-indicator.mdc-tab--active.ng-star-inserted{
	height: 48px;
}
.mat-mdc-slide-toggle.mat-checked .mat-slide-toggle-thumb {
	background-color: lighten($button-main-color, 8%);
}

.details-bottom .mat-mdc-tab-header {
	border-top: gray;
	background-color: #f9f9f9;
}
.media-details-bottom .mat-mdc-tab-header {
	border-top: gray;
	background-color: #f9f9f9;
}

.mat-mdc-row {
	transition: 0.3s ease-in-out;
	.edit-icon, .icon-bg {
		opacity: 0;
		transition: 0.3s ease-in-out;
	}
	&:hover {
		background: rgba(30, 136, 229, 0.03);
		.edit-icon, .icon-bg {
			opacity: 1;
		}
	}
}
.playlist_box {
	display: flex;
	height: 50vh;
	align-items: center;
	justify-content: center;

	.playlist_info {
		margin: 0px auto;
		display: flex;
		align-items: center;
		justify-content: center;
		height: auto !important;
		padding: 40px !important;
	}
}
.mat-mdc-unelevated-button {
	height: 40px;
}

//*inside mat-button content align center*
.mat-button-wrapper {
	display: flex;
	align-items: center;
	justify-content: center;
}

.tabs-box {
	.mat-tab-labels {
		justify-content: space-evenly;

		.mat-tab-label-content {
			font-size: 10px;
		}
	}
	.mat-ripple.mat-tab-label {
		min-width: auto;
		padding: 0px 5px;
	}
	.form-label {
		align-self: center;
		font-size: 12px;
	}
}

.search-element {
	border: 1px solid #f9f9f9;
	background-color: #f9f9f9;
	border-radius: 12px;
	max-width: 215px;
	flex-wrap: nowrap !important;
	.mat-form-field-appearance-outline .mat-form-field-infix {
		padding: 5px 0 10px 0;
		margin-top: 0px;
	}
	&.input-full {
		max-width: 100%;
	}
}
.fnt-weight-500 {
	font-weight: 500;
}
.tag-element {
	border-radius: 7px;
	border: 1px solid #f1f1f1;
	background: #f1f1f1;
	padding: 5px 4px 5px 4px;
	font-size: 15px;
}
.pr-7 {
	padding: 0px 7px 0px 0px;
}
.form-input {
	font-size: 13px;
	padding-left: 2rem;
	font-weight: 400;
	color: #000;
}

/* width */
::-webkit-scrollbar {
	width: 6px;
}

/* Track */
::-webkit-scrollbar-track {
	border-radius: 10px;
}

/* Handle */
::-webkit-scrollbar-thumb {
	background: #dedede;
	border-radius: 10px;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
	background: rgb(114, 114, 114);
}

.tabs-container {
	overflow-x: scroll;
	padding: 22px;
}
.bg-light-grey {
	border-radius: 12px;
	background-color: #f9f9f9;
}
.bg-light {
	background-color: $gray-100 !important;
}

.mat-mdc-dialog-container {
	background-color: #f9f9f9;
	padding: 0px !important;
	border-radius: 6px;
}

.cdk-overlay-container {
	z-index: 9999 !important;
}
.modal-dailog-box {
	padding: 20px 0px;
}
.text-success {
	color: $green !important;
}
.text-success::before {
	color: $green !important;
}

.right-side:last-child {
	border-left: 1px solid #a4a4a4;
}

.mdc-linear-progress__bar-inner {
	--mdc-linear-progress-active-indicator-color: #7fc88b;
}
.mat-calendar-table-header-divider::after {
    background: rgba(0, 0, 0, .12) !important;
}
.schedule-calender {
	.cal-cell {
		font-weight: normal;
		color: #a4a4a4 !important;
	}
}
.bg-light-1 {
	background: #f9f9f9;
}

.schedule-input {
	.mat-form-field-wrapper {
		padding-bottom: 0px;
	}
	
	.mat-form-field-infix {
		background: red;
	}
}
.cal-month-view .cal-day-cell {
	position: relative;
	min-height: 12vh;
	min-width: 1vh; // else width is exceeding container
	small {
		margin: 0px !important;
		position: absolute;
		bottom: 0;
		p {
			margin-bottom: 0px !important;
		}
	}
}
.day-number {
	font-size: 23px;
	line-height: 29px;
	font-weight: 500;
}
.day-label {
	color: #a4a4a4;
	font-size: 14px;
	line-height: 12px;
}

.cal-event-container {
	padding: 0 4px;
}

.cal-week-view .cal-time-events .cal-event {
	border-radius: 0;
	line-height: 10px;
	box-shadow:
	    0px 2px 1px -1px rgb(0 0 0 / 20%),
	    0px 1px 1px 0px rgb(0 0 0 / 14%),
	    0px 1px 3px 0px rgb(0 0 0 / 12%)
}

.cal-month-view .cal-cell-top {
	text-align: center;
	min-height: auto;
	display: flex;
	margin: 0px;
	.cal-day-number {
		margin-right: 0px;
		float: none;
		margin-bottom: 0px;
		margin-top: 5px;
	}
}
.cal-month-view .cal-day-cell.cal-today .cal-day-number {
	margin: 5px auto 0;
	font-size: 16px;
	height: 25px;
	width: 25px;
	border-radius: 50%;
	line-height: 25px;
	color: #fff;
	opacity: 1;
}
.cal-week-view {
	border-top: none !important;
}
.cal-month-view .cal-header {
	position: sticky;
	top: 0;
	z-index: 99;
	background: #fff;
	border-top: 1px solid #e1e1e1;
}
.cal-week-view .cal-day-headers {
	border-top: 1px solid #e1e1e1 !important;
	position: sticky;
	top: 0;
	z-index: 999;
	background: #fff;
}
.cal-week-view .cal-day-headers .cal-header:not(:last-child) {
	border-right: none !important;
}
.ngx-mat-timepicker-meridian {
	.mat-button-wrapper {
		justify-content: center;
	}
}

.input-border-bottom {
	border: none;
	outline: none;
	padding: 5px;
	background: transparent;
	&.active {
		border-bottom: 1px solid #ccc;
	}
}
.edit-icon {
	cursor: pointer;
}

.schedule-overflow {
	.mat-checkbox-layout .mat-checkbox-label {
		color: #575757;
		font-weight: 500;
	}
	/* width */
	::-webkit-scrollbar {
		width: 7px;
	}
}
.schedule-chips {
	overflow-x: hidden;
	.mat-form-field-appearance-legacy {
		
		.mat-form-field-infix {
			padding-top: 0px;
			border-top: 0px;
		}
		
		.mat-form-field-underline {
			display: none;
		}
		
		.mat-form-field-wrapper {
			padding-bottom: 0px;
		}
	}
}
.dropup,
.dropright,
.dropdown,
.dropleft {
	z-index: 9999 !important;
}

.checkout-order-summary {
    height: calc(100vh - 65px);
}

.sync_info {
	display: flex;
	height: 20vh;
	align-items: center;
	justify-content: center;
}
.box-accordion {
	.mat-expansion-panel-header.mat-expanded {
		height: 40px;
		background: #efefef;
		border-radius: 0px;
	}
}
.map-box {
	.map-container {
		width: 100% !important;
		height: 250px !important;
	}
}
.dashboard-display {
	height: 48vh;
	&.display-box {
		height: 44vh;
		.media-list {
			height: calc(44vh - 50px);
			max-height: 44vh;
			overflow-x: hidden;
		}
		.playlist_box {
			height: 36vh;
		}
	}
	.media-list {
		height: calc(48vh - 105px);
		max-height: 48vh;
	}
	.playlist_box {
		height: 25vh;
	}
}

// setting module
.session-table {
	height: 450px;
	overflow: auto;
}
.select-display {
	max-width: 400px;
	margin: 0px auto;
}
@media (max-width:1024px) {
	.setting-btn {
		width: 100% !important;
	}
	.pop-header {
		gap: 10px;
	}
	.search-input-2 {
		gap: 10px;
	}
	.filter-btn-box {
		max-width: 40% !important;
	}
}
@media only screen and (max-width: 768px) {
	.setting_popups{
		width: 100%;
		height: 100%;
	}
  }
@media (max-width:769px) {
	.select-display{
		max-width: 300px;
	}
	.transition_popups {
		display: block !important;
		
	}
	.mob-dialog-setting {
        height: 100vh;
       width: auto !important;
    }
	.settings_sections {
		width: 90vw !important;
	}
	.settings_popups {
		max-width: 40%;
	}
	.settings_colums {
		max-width: 60%;
	}
	.btn-group.custom-mat-input {
		text-align: center;
	}
	.logo-data-name td.mat-mdc-cell.mdc-data-table__cell {
		padding: 0px 20px;
	}
	.mat-mdc-dialog-surface{
		overflow-x: hidden !important;
	}
	.alert-titel{
		padding: 0px 20px !important;
	}
	.alert-form {
		padding: 0px 20px !important;
	}
	.alert-text{
		max-width: 100% !important;
	}
	.mat-icon-button{
		text-align: center !important;
	}
	
}
@media (max-width:767px) {
	.hitem.cursor {
		display: block !important;
		margin: 0 !important;
	}
	.hitem.cursor {
		margin: 0 !important;
		margin-bottom: 8px !important;
	}
	.pop-header {
		display: block !important;
	}
	.hitem.cursor mat-form-field.mat-mdc-form-field{
		width: 120px !important;
	} 
	.search-4 {
		max-width: 50% !important;
	}
	.search_btn-box{
		max-width: 70% !important;
	}
	.filter-btn-box{
		max-width: 30% !important;
	}
	.display-btn_box{
		max-width: 50% !important;
	}
}
@media (max-width:600px) {
	.mat-mdc-outlined-button{
		padding: 0px 15px !important;
	}
	.session-table {
		height: calc(90vh - 50px) !important;
		width: 100% !important;
		overflow: auto !important;
	}
	
	.mat-icon-button{
		padding-right: 0px;
	}
	.session-table table{
		overflow: auto !important;
		height: 50%;
		width: 100%;
	}
	.alerts-option {
		width: 40% !important;
	}
	.reports-email {
		margin-left: 0px !important;
		width: 100% !important;
	}
	.icons-search-select {
		right: 10px !important;
	}
	.Select-displays-chip{
		width: 100% !important;
	}
	.alert-text {
		gap: 10px;
	}
	.choose-options{
		display: block !important;
	}
	.alerts-option {
        width: 100% !important;
        margin: 0 !important;
    }
	.title{
		padding: 24px !important;
	}
	.form-buttons {
		padding: 20px 24px !important;
	}
	.inputs-2 {
		padding: 0px 24px !important;
	}
	.add-buttons {
		text-align: center !important;
	}
	.field-form{
		padding: 24px 24px 0px !important;
	}
	.confirm.mdc-button.mdc-button--unelevated.mat-mdc-unelevated-button.mat-primary.mat-mdc-button-base {
		width: 100% !important;
		margin: 0 !important;
	}
	button.cancel {
		width: 100% !important;
		margin-bottom: 10px;
	}
	.plus-butn-3 {
		margin: 20px 52px 0px 32px !important;
	}
	.table_power {
		padding: 0px 24px !important;
	}
	.policy-hedding {
		padding: 0 !important;
	}
	
}
@media (max-width:425px) {
	.btn-group.custom-mat-input mat-form-field{
		width: 90% !important;
	}
	.mat-icon-button{
		padding-right: 0px !important;
	}
	.select-display {
		justify-content: left !important;
	}
	.select-display {
		padding: 0px 18px !important;;
	}
	.box-transition{
		width: 100% !important;
	}
	button.confirm {
		width: 100% !important;
	}
	button.cancel {
		width: 100% !important;
	}
	.policy-title{
		font-size: 20px ;
	}
	.mat-mdc-input-element{
		width: 100% !important;

	}
	.select-display {
		width: 100% !important;
	}
	button.btn.btn-outline-danger {
		margin-bottom: 8px;
		margin-left: 0 !important;
	}
	button.btn.alert-cancel-btn {
		width: 100%;
		margin: 0 !important;
		margin-bottom: 10px !important;
	}
	.d-block.setting-btn {
		padding: 0 20px !important;
		margin-bottom: 20px !important;
	}
	.setting-btn button.btn.btn-primary {
		width: 100%;
	}
}
@media (max-width:375px) {
	.inputs-2{
		max-height: 24vh !important;
	}
	.policy-title{
		font-size: 16px !important;
	}
}
.upload-btn-wrapper {
	position: relative;
	overflow: hidden;
	display: inline-block;
	.btn-upload {
		padding: 30px;
		background: #afafaf;
		color: #fff;
		font-size: 30px;
		width: 230px;
		height: 230px;
		span {
			font-size: 14px;
		}
	}

	input[type="file"] {
		font-size: 0px;
		position: absolute;
		left: 0;
		top: 0;
		opacity: 0;
	}
}

.cal-week-view .cal-hour:not(:last-child) .cal-hour-segment,
.cal-week-view .cal-hour:last-child :not(:last-child) .cal-hour-segment {
	border-bottom: 1px solid #e1e1e1;
}
.associated-element {
	.mat-expansion-panel-body {
		padding: 0px;
	}
}
.tabs-box {
	border-top: 1px solid #e8e8e8;
}
.tabs-box {
	.card-listing {
		height: calc(100vh - 215px);
	}
}
.sub-directory {
	.card-listing {
		height: calc(100vh - 297px);
	}
}
.mdc-switch.mdc-switch--selected.mdc-switch--checked {
    background: #1e88e5;
}
.mdc-switch.mdc-switch--selected.mdc-switch--checked {
    background: #1e88e5;
}
.mdc-switch {
    width: 38px;
    height: 14px;
    background: #1e88e5;
    border-radius: 50px;
}
.mdc-switch__icons {
    display: none;
}
.mdc-switch__handle {
    width: 20px !important;
    height: 20px !important;
    background: white;
    border-radius: 100px !important;
    box-shadow: 0 2px 1px -1px rgba(0, 0, 0, .2), 0 1px 1px 0 rgba(0, 0, 0, .14), 0 1px 3px 0 rgba(0, 0, 0, .12);
}
.mdc-switch {
    width: 38px !important;
    height: 14px !important;
    background: #1e88e5;
    border-radius: 50px;
}
.alert-switch-btn .mdc-switch.mdc-switch--selected:enabled .mdc-switch__handle::after {
    background: #ffffff !important;
  }
  
.mdc-switch__icons {
    display: none;
}
.mdc-switch__handle {
    width: 20px !important;
    height: 20px !important;
    background: white;
    box-shadow: 0 2px 1px -1px rgba(0, 0, 0, .2), 0 1px 1px 0 rgba(0, 0, 0, .14), 0 1px 3px 0 rgba(0, 0, 0, .12);
}
.mdc-switch__handle-track{
	top: 6.5px !important;
}
.mdc-switch__handle{
	position: static !important;
}

.alert-switch-btn .mdc-switch.mdc-switch--selected.mdc-switch--checked{
	background: #1e88e5 !important;
	border-color: #1E88E5 !important;
}
.mdc-switch.mdc-switch--selectedmdc-switch--checked {
    background: #1e88e5 ;
}
.mdc-switch.mdc-switch--unselected {
    background: #00000061;
}

.alert-switch-btn .mdc-switch {
    height: 30px !important;
    width: 50px !important;
    background: #E2EFFB !important;
    border: 2px solid #575757 !important;
}
.alert-switch-btn .mdc-switch__handle-track {
    top: 12.6px !important;
}
.alert-switch-btn .mdc-switch__handle {
    width: 16px !important;
    height: 16px !important;
    background: #575757;
    margin-left: 4px !important;
}


.theme-dark {
	.mat-mdc-slide-toggle .mdc-switch:enabled .mdc-switch__track::after{
		--mdc-switch-selected-track-color: #{$gray-700};
	}
	.mat-mdc-checkbox .mdc-checkbox .mdc-checkbox__native-control:enabled:not(:checked):not(:indeterminate):not([data-indeterminate=true])~.mdc-checkbox__background{
		border-color: $gray-500;
	}
	.mat-mdc-row:not(.selected):hover{
		background-color: #{$primary}20;
	}
	.mat-mdc-row:not(.selected):hover .mdc-data-table__cell span{
		color: #1e88e5!important;
	}
	.mat-mdc-row:not(.selected):hover .mdc-data-table__cell p{
		color: #1e88e5!important;
	}
	.mdc-switch.mdc-switch--unselected{
		background: #ffffff80 !important;
	}
	.mdc-switch__handle{
		background: #bdbdbd !important;
	}
	
	.btn-sidebar-bottom{
	    button{
	        border-radius: 50px;
	        span{
	            color:$blue;
	        }
	    }
	}
	.btn-primary {
		color: white;
		border: none;
		&:hover {
			background-color: $button-main-color;
		}
	}
	.mat-mdc-menu-trigger {
		.mat-icon {
			color: $white;
		}
	}
	h6 {
		input {
			color: $white;
		}
	}
	.card {
		background: $gray-200;
	}
	.bg-light {
		background: $gray-200 !important;
	}
	.bg-light-grey {
		background: $sidebar-dark-menu-item-hover-color;
	}
	.mat-mdc-table {
		color: $gray-500;
		background: $gray-200;
	}
	.schedule-box {
		.btn-group {
			background-color: rgb(25 26 28) !important;
		}
	}
	.cal-month-view .cal-header {
		background: $gray-200;
		border-top: 1px solid $gray-200;
	}
	.cal-week-view .cal-day-headers {
		background: $gray-200 !important;
	}
	.btm_crd {
		background: $gray-200 !important;
	}
	.table thead th {
		vertical-align: middle;
		background: #212121;
	}
	.search-element {
		background: $gray-900;
		border: 1px solid $gray-900;
	}
	.mat-mdc-card {
		background: $gray-900;
		.grid_view{
			background: $gray-800;
		}
	}
	.mat-tab-label-active {
		color: $primary;
	}

	.media-list {
		color: $gray-400;
		.icon-info {
			&:before {
				color: $white;
			}
		}
	}
	.report_type_butn span.mdc-button__label{
		background: rgba(30,136,229,.22) !important;
		color:#fff !important;
	}
	.mat-mdc-header-cell {
		background: #212121 !important;
		font-size: 12px !important;
		color: #ffffffb3;
	}
	.details-top, .media-details-top {
		background: rgb(29, 34, 46, 0.2);
	}
	.bg-light-1 {
		background: rgba(29, 34, 46, 0.2);
	}
	.mat-mdc-dialog-container {
		background: $darkbg;
	}
	.bg-white {
		background-color: #141414 !important;
	}
	.details-bottom .mat-mdc-tab-header {
		background-color: $gray-50;
	}
	.tabs-box {
		border-top: 1px solid $gray-50;
		.btn-group .btn{
			color: $gray-100;
		}
	}
	.mat-mdc-row.selected {
		background: rgb(157, 195, 227, 0.2) !important;
	}
}

 .section--overlay .mat-checkbox-inner-container{
	 width: 0.8rem !important;
	 height:0.8rem !important;
 }
 
.btn.btn-outline-secondary:hover {
	color: $white;
	background-color: $blue;
}
.btn.btn-outline-secondary {
	color: $blue;
}
.cal-time {
	font-family: Inter;
	font-style: normal;
	font-weight: unset !important;
	font-size: 12px;
	color: #a4a4a4;
	line-height: 16px;
}

// layout setup
.layout-setup {
	.mat-tab-label {
		height: 40px;
	}
}

.layout-tag-select {
	margin: 0 0 0 38px;
	.layout-tag-list {
		width: 100%;
		border: 1px solid #dedede;
		padding: 5px 10px;
		
		
		.mat-form-field-wrapper {
			padding-bottom: 0;
			
			.mat-form-field-infix {
				border-top: 0px;
			}
		}
		
		.mat-form-field-underline {
			height: 0;
			opacity: 0;
		}
	}
}

.hitem {
	.mat-mdc-select {
		padding: 10px;
	}
}

.setting-btn {
	right: 0;
	width: 100%;
	text-align: right;
	bottom: 0px;
	display: flex;
	@media(min-width:768px){
		display: block;
		position: absolute;
	}
}

.day-cb {
	.mat-checkbox-layout {
		display: block;
		text-align: center;
		padding-right: 20px;

		.mat-checkbox-inner-container {
			display: block;
			margin-inline: inherit;
		}
	}
}
@media (max-width:767px) {
	.day-cb {

		.mat-checkbox-layout {
			padding-right: 10px;
			font-size: 0.7rem;
		}
	}
}

.custom-mat-select {
	.mat-mdc-form-field {
		width: 250px;
		
		.mat-form-field-wrapper {
			padding-bottom: 0;
		}
		
		.mat-form-field-underline {
			display: none;
		}
		
		.mat-form-field-infix {
			border: 1px solid #ccc;
			line-height: 25px;
			border-radius: 5px;
		}
		
		.mat-form-field-label-wrapper {
			left: 10px;
		}
		
		
		&.mat-form-field-should-float {
			
			.mat-form-field-label-wrapper {
				display: none;
			}
		}
	}
}

.custom-mat-input {
	.mat-mdc-form-field {
		position: relative;
		border: 1px solid #ccc;
		border-radius: 5px;
		
		.mat-form-field-wrapper {
			padding-bottom: 0px;
		}
		
		.mat-form-field-label-wrapper {
			left: 10px;
		}
		
		.mat-form-field-underline {
			display: none;
		}
		
		.mat-form-field-infix {
			border-top: none;
		}
		
		
		&.mat-form-field-should-float {
			
			.mat-form-field-label-wrapper {
				display: none;
			}
		}
	}
}
.fas.fa-chevron-left{
	color: $button-main-color;
}
// regular style toast
// @import 'ngx-toastr/toastr';

// bootstrap style toast
// or import a bootstrap 4 alert styled design (SASS ONLY)
// should be after your bootstrap imports, it uses bs4 variables, mixins, functions
// @import 'ngx-toastr/toastr-bs4-alert';

// if you'd like to use it without importing all of bootstrap it requires
// @import 'bootstrap/scss/functions';
// @import 'bootstrap/scss/variables';
// @import 'bootstrap/scss/mixins';
// @import 'ngx-toastr/toastr-bs4-alert';


.ngx-toastr {
	width: 30rem !important;
}
.toast-success, .toast {
 	border-color: #8EF3C5;
 	background-color: #8EF3C5 !important;
 	color:#42A084 !important;
	border:0px solid !important;
 	border-radius: 0px !important;
   }
   .toast-error {
 	background-color: #FFE28C !important;
	 color: #BB992F;
	 border:0px solid !important;
   }
.ngx-charts .label{
	fill: $black;
}

.settings-nav-item.nav-link{
	display: block;
	padding-left: 1rem;
	font-weight: 500;
}
.settings-nav-item.nav-link.active{
	background-color: transparent !important;
	span{
		color: $menu-item-active-color !important;
	}
}
  @media(max-width:767px) {
	.ngx-toastr {
		width: 20rem !important;
	}
	//   add button
	.add-btn-mob{
		display: flex !important;
		align-items: center;
		justify-content: center;
		height: 42px;
		width: 42px;
		border-radius: 21px !important;
		position: fixed;
		right: 5%;
		bottom: 5%;
		box-shadow: 0 4px 10px rgb(0 0 0 / 20%);

		.mat-icon{
			min-width: 24px;
		}
	}
	.cdk-global-overlay-wrapper{
		align-items: flex-end !important;
	}
	  .cdk-overlay-pane{
		background-color: $gray-200;
		max-width: 100% !important;
		// height: 100vh !important;
		position: absolute !important;
		}
		.form-buttons{
			position: absolute;
    		bottom: 0;
    		width: 100%;
    		left: 0;
			display: block !important;
			.btn{
				height: 40px;
				width:46%;
			}
		}
		.select-media-mobile{
			height: 80vh;
			width: 100vw;
		}
		.title{
			border-bottom-right-radius: 8px;
			border-bottom-left-radius: 8px;
			// box-shadow: 0px 1px 3px rgb(0,0,0,0.10);
			// background: #fff;
		}

		// fixed btn position
		.mob-btn-position{
			position: relative !important;
			text-align: center !important;
			background: none !important;
			height: 10vh;

			button{
				width: 46%;
				height: 40px;
			}
		}
		.card-body {
			height: 100vh;
			width: 100vw;
		}

		.mob-rows{
			border-left: 4px solid transparent;
			&:hover{
				background: transparent !important;
			}
		}

		.mob-rows.selected{
			background: $table-row-hover !important;
			border-left: 4px solid $button-main-color;
		}
		
		.mob-settings-back{
			position: fixed;
			top: 3%;
			left: 6%;
		}
		.mob-dialog-setting{
			height: 100vh;
			width: 100vw;

			.mob-btn-position{
				position: absolute !important;
			}
		}
		.card {
			border-radius: 0 !important;
			box-shadow: unset !important;
			margin-bottom: 0 !important;
		}
		
		.layout-receive-list{
			.cstm_plist_crd{
				height: calc(100vh - 360px) !important;
			}
		}
		.dashboard-display{
			height: 52vh;
		}
	}

.modal-dailog-boxes,.custom-dailog{
	.title {
		background: #fff;
		box-shadow: 0px 1px 3px rgb(0,0,0,0.10);
		height: 8vh;
		position: sticky;
		display: flex;
		align-items: center;
		justify-content: center;
		h6{
			margin-bottom: 0px;
		}
		@media(min-width: $tablet-width){
			background: transparent;
			padding-top:15px;
			box-shadow: none;
		}
	}

}

@media (max-width:$mobile-width){
	.mat-mdc-outlined-button,.mat-mdc-unelevated-button.mat-primary, .mat-mdc-raised-button.mat-primary, .mat-mdc-fab.mat-primary, .mat-mdc-mini-fab.mat-primary{
		width:48% !important;
	}
	.key-input{
		margin-bottom: 0px;
	}
	.mat-tab-label, .mdc-tab{
		font-size:11px;
		padding: 0px 0px !important;
		width: 33%;
	}
	.pl-5,.pr-5{
		padding-left: 10px !important;
		padding-right: 10px !important;
	}
	.vertical-collpsed .vertical-menu #sidebar-menu > ul > li{
		text-align: center;
		.mat-mdc-icon-button i, .mat-icon-button .mat-icon{
			font-size:24px;
		}
		button i{
			font-size: 24px;
		}
	}
}


.mat-mdc-dialog-container{
	.playlist, .schedule, .layout{
		padding: 7px;
		padding-right: 0 !important;
		.col-lg-8{
			padding: 0;
		}
	}
}

.cdk-virtual-scroll-orientation-vertical .cdk-virtual-scroll-content-wrapper{
	width: 100%;
}

@media (max-width: 768px) {
	.mat-mdc-dialog-container .mdc-dialog__surface{
		padding: 0.3em 0;
	}
}


th.mat-mdc-header-cell:first-of-type, td.mat-mdc-cell:first-of-type, td.mat-mdc-footer-cell:first-of-type{
	padding-right: 0 !important;
	width: 60px;
	min-width: 60px;
	
	@media (max-width: 1300px){
		width: 50px;
		min-width: 50px;
	}
}

ngx-mat-datetime-content .actions .mdc-button--outlined{
	background-color: $primary;
	color: $white;
}
.mdc-tab{
	padding: 0 5px !important;
	min-width: 64px !important;
}

.layout-setup .mdc-tab.mat-mdc-tab.mat-mdc-focus-indicator {
	min-width: 160px !important;
}

.displays-list  .mdc-tab.mat-mdc-tab.mat-mdc-focus-indicator {
	min-width: 160px !important;
}
